import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Query } from "~/lqs";
import { LqsResourceTable, useLogQueries } from "~/lqs";
import {
  DataStoreLink,
  makeLogQueryLocation,
  makeNewLogQueryLocation,
  useLogParams,
} from "~/paths";
import { listLogQueriesSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Query>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(logQuery) {
      return (
        <TableCell>
          {logQuery.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeLogQueryLocation({
              logId: logQuery.logId,
              queryId: logQuery.id,
            })}
          >
            <Typography variant="body2">{logQuery.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useLogQuerySearchRequest, LogQuerySearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listLogQueriesSchema,
  });

export function LogQueryTable() {
  const { logId } = useLogParams();

  const [request, setRequest] = useLogQuerySearchRequest();

  const searchQuery = useLogQueries(logId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="query"
      resourceCreateLocation={makeNewLogQueryLocation({ logId })}
      getRowKey={(logQuery) => logQuery.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
