import type {
  UseInfiniteQueryOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { convertBase64ImageToBlob, MultipartUploader } from "~/domain/network";
import { secondsToMilliseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import type { KeyFactory, Maybe, SimpleQueryResult } from "~/types";
import { combineQueries, stringifyBigintFields } from "~/utils";
import { useDataStoreClients } from "../context";
import { ResponseError } from "../sdk";
import type {
  CloudObject,
  ListLogObjectsRequest,
  ListLogsRequest,
  ListQueriesRequest,
  ListTagsRequest,
  Log,
  LogApi,
  LogCreateRequest,
  LogUpdateRequest,
  ObjectDataResponse,
  ObjectListResponse,
  ObjectPartCreateRequest,
  Query,
  QueryCreateRequest,
  QueryDataResponse,
  QueryListResponse,
  QueryUpdateRequest,
  Tag,
  TagCreateRequest,
  TagDataResponse,
  TagListResponse,
  TagUpdateRequest,
  LogListResponse,
  LogDataResponse,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import {
  createResourceCrudHooks,
  getInitialDetailsData,
  mergeEnabledOption,
} from "./utils";

export type PreviewImageResult =
  | { success: true; blob: Blob | null }
  | { success: false; blob: null };

export interface PreviewImageUpload {
  image: Blob;
  index: number;
  deleteExisting: boolean;
}

const {
  queryKeyFactory: initialLogKeys,
  useList: useLogs,
  useFetch: useLog,
  useCreate: useCreateLog,
  useUpdate: useUpdateLog,
  useDelete: useDeleteLog,
} = createResourceCrudHooks({
  baseQueryKey: "logs",
  getIdentifier(log: Log) {
    return log.id;
  },
  listResource({ signal }, { logApi }, request: ListLogsRequest) {
    return logApi.listLogs(request, { signal });
  },
  fetchResource({ signal }, { logApi }, logId: Log["id"]) {
    return logApi.fetchLog({ logId }, { signal });
  },
  createResource({ logApi }, request: LogCreateRequest) {
    return logApi.createLog({ logCreateRequest: request });
  },
  updateResource({ logApi }, logId: Log["id"], updates: LogUpdateRequest) {
    return logApi.updateLog({ logId, logUpdateRequest: updates });
  },
  deleteResource({ logApi }, logId: Log["id"]) {
    return logApi.deleteLog({ logId });
  },
});

export { useLogs, useLog, useCreateLog, useUpdateLog, useDeleteLog };

const logKeys = {
  ...initialLogKeys,
  allPreviewImages: (logId: Log["id"] | null) =>
    [...initialLogKeys.fetch(logId), "preview-images"] as const,
  previewImages: (
    logId: Log["id"] | null,
    base64EncodedImages: Maybe<ReadonlyArray<string>>,
  ) => [...logKeys.allPreviewImages(logId), base64EncodedImages] as const,
};

export { logKeys };

export function useLogsQueryOptionsFactory(): (
  request: ListLogsRequest,
) => LqsQueryOptions<LogListResponse> {
  const { logApi } = useDataStoreClients();

  return (request) => ({
    queryKey: logKeys.list(request),
    queryFn({ signal }) {
      return logApi.listLogs(request, { signal });
    },
  });
}

export function useLogQueryOptionsFactory(): (
  logId: Log["id"] | null,
) => LqsQueryOptions<LogDataResponse, "enabled"> {
  const { logApi } = useDataStoreClients();

  return (logId) => {
    const enabled = logId !== null;

    return {
      queryKey: logKeys.fetch(logId),
      queryFn({ signal }) {
        invariant(enabled, "Log ID not provided");

        return logApi.fetchLog({ logId }, { signal });
      },
      enabled,
    };
  };
}

export type LogKeys = KeyFactory<typeof logKeys>;

export const tagKeys = {
  all: (logId: Log["id"] | null) => [...logKeys.fetch(logId), "tags"] as const,
  lists: (logId: Log["id"] | null) => [...tagKeys.all(logId), "list"] as const,
  list: (
    logId: Log["id"] | null,
    request: StrictOmit<ListTagsRequest, "logId">,
  ) => [...tagKeys.lists(logId), stringifyBigintFields(request)] as const,
  fetches: (logId: Log["id"]) => [...tagKeys.all(logId), "fetch"] as const,
  fetch: (logId: Log["id"], tagId: Tag["id"]) =>
    [...tagKeys.fetches(logId), tagId] as const,
};

export type TagKeys = KeyFactory<typeof tagKeys>;

export function useTags<TData = TagListResponse>(
  logId: Log["id"] | null,
  request: StrictOmit<ListTagsRequest, "logId">,
  options?: UseQueryOptions<TagListResponse, unknown, TData, TagKeys["list"]>,
): UseQueryResult<TData> {
  const { logApi } = useDataStoreClients();

  const isLogIdDefined = logId !== null;

  return useQuery({
    queryKey: tagKeys.list(logId, request),
    queryFn({ signal }) {
      invariant(isLogIdDefined, "Log ID is null");

      return logApi.listTags({ logId, ...request }, { signal });
    },
    ...options,
    enabled: mergeEnabledOption(options, isLogIdDefined),
  });
}

export function useTag<TData = TagDataResponse>(
  logId: Log["id"],
  tagId: Tag["id"],
  options?: StrictOmit<
    UseQueryOptions<TagDataResponse, unknown, TData, TagKeys["fetch"]>,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useQuery({
    queryKey: tagKeys.fetch(logId, tagId),
    queryFn({ signal }) {
      return logApi.fetchTag({ logId, tagId }, { signal });
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        tagKeys.lists(logId),
        (tag: Tag) => tag.logId === logId && tag.id === tagId,
      );
    },
  });
}

export function useCreateTag(
  logId: Log["id"],
): UseMutationResult<TagDataResponse, unknown, TagCreateRequest> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return logApi.createTag({ logId, tagCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<TagDataResponse>(
        tagKeys.fetch(logId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateTag(
  logId: Log["id"],
  tagId: Tag["id"],
): UseMutationResult<TagDataResponse, unknown, TagUpdateRequest> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn(updates) {
      return logApi.updateTag({ logId, tagId, tagUpdateRequest: updates });
    },
    onSuccess(response) {
      queryClient.setQueryData<TagDataResponse>(
        tagKeys.fetch(logId, response.data.id),
        response,
      );
    },
  });
}

export function useDeleteTag(
  logId: Log["id"],
  tagId: Tag["id"],
): UseMutationResult<void, unknown, void> {
  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return logApi.deleteTag({ logId, tagId });
    },
  });
}

export const logObjectKeys = {
  all: (logId: Log["id"]) => [...logKeys.fetch(logId), "objects"] as const,
  lists: (logId: Log["id"]) => [...logObjectKeys.all(logId), "list"] as const,
  list: (
    logId: Log["id"],
    request: StrictOmit<ListLogObjectsRequest, "logId" | "continuationToken">,
  ) => [...logObjectKeys.lists(logId), request] as const,
  fetches: (logId: Log["id"]) =>
    [...logObjectKeys.all(logId), "fetch"] as const,
  fetch: (logId: Log["id"], key: CloudObject["key"]) =>
    [...logObjectKeys.fetches(logId), key] as const,
};

export function useLogObjectsQueryOptionsFactory(): (
  logId: Log["id"],
  request: StrictOmit<ListLogObjectsRequest, "logId" | "continuationToken">,
) => UseInfiniteQueryOptions<ObjectListResponse> {
  const { logApi } = useDataStoreClients();

  return (logId, request) => ({
    queryKey: logObjectKeys.list(logId, request),
    queryFn({ signal, pageParam: continuationToken }) {
      return logApi.listLogObjects(
        { logId, ...request, continuationToken },
        { signal },
      );
    },
  });
}

export function useLogObjectQueryOptionsFactory(): (
  logId: Log["id"],
  objectKey: CloudObject["key"],
) => Pick<UseQueryOptions<ObjectDataResponse>, "queryKey" | "queryFn"> {
  const { logApi } = useDataStoreClients();

  return (logId, objectKey) => ({
    queryKey: logObjectKeys.fetch(logId, objectKey),
    queryFn({ signal }) {
      return logApi.fetchLogObject({ logId, objectKey }, { signal });
    },
  });
}

export function useLogObject<TData = ObjectDataResponse>(
  logId: Log["id"],
  key: CloudObject["key"],
  options?: StrictOmit<
    UseQueryOptions<ObjectDataResponse, unknown, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
): UseQueryResult<TData> {
  const createLogObjectQueryOptions = useLogObjectQueryOptionsFactory();

  return useQuery({
    ...createLogObjectQueryOptions(logId, key),
    ...options,
  });
}

export function useDeleteLogObject(
  logId: Log["id"],
  key: CloudObject["key"],
): UseMutationResult<void, unknown, void> {
  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return logApi.deleteLogObject({ logId, objectKey: key });
    },
  });
}

const logQueryKeys = {
  all: (logId: Log["id"]) => [...logKeys.fetch(logId), "queries"] as const,
  lists: (logId: Log["id"]) => [...logQueryKeys.all(logId), "list"] as const,
  list: (logId: Log["id"], request: StrictOmit<ListQueriesRequest, "logId">) =>
    [...logQueryKeys.lists(logId), request] as const,
  fetches: (logId: Log["id"]) => [...logQueryKeys.all(logId), "fetch"] as const,
  fetch: (logId: Log["id"], queryId: Query["id"]) =>
    [...logQueryKeys.fetches(logId), queryId] as const,
};

type LogQueryKeys = KeyFactory<typeof logQueryKeys>;

export function useLogQueries<TData = QueryListResponse>(
  logId: Log["id"],
  request: StrictOmit<ListQueriesRequest, "logId">,
  options?: UseQueryOptions<
    QueryListResponse,
    unknown,
    TData,
    LogQueryKeys["list"]
  >,
): UseQueryResult<TData> {
  const { logApi } = useDataStoreClients();

  return useQuery({
    queryKey: logQueryKeys.list(logId, request),
    queryFn({ signal }) {
      return logApi.listQueries({ logId, ...request }, { signal });
    },
    ...options,
  });
}

export function useLogQuery<TData = QueryDataResponse>(
  logId: Log["id"],
  queryId: Query["id"],
  options?: StrictOmit<
    UseQueryOptions<QueryDataResponse, unknown, TData, LogQueryKeys["fetch"]>,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useQuery({
    queryKey: logQueryKeys.fetch(logId, queryId),
    queryFn({ signal }) {
      return logApi.fetchQuery({ logId, queryId }, { signal });
    },
    ...options,
    initialData() {
      getInitialDetailsData(
        queryClient,
        logQueryKeys.lists(logId),
        (logQuery: Query) =>
          logQuery.logId === logId && logQuery.id === queryId,
      );
    },
  });
}

export function useCreateLogQuery(
  logId: Log["id"],
): UseMutationResult<QueryDataResponse, unknown, QueryCreateRequest> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return logApi.createQuery({ logId, queryCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<QueryDataResponse>(
        logQueryKeys.fetch(logId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateLogQuery(
  logId: Log["id"],
  queryId: Query["id"],
): UseMutationResult<QueryDataResponse, unknown, QueryUpdateRequest> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn(updates) {
      return logApi.updateQuery({
        logId,
        queryId,
        queryUpdateRequest: updates,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<QueryDataResponse>(
        logQueryKeys.fetch(logId, response.data.id),
        response,
      );
    },
  });
}

export function useDeleteLogQuery(
  logId: Log["id"],
  queryId: Query["id"],
): UseMutationResult<void, unknown, void> {
  const { logApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return logApi.deleteQuery({ logId, queryId });
    },
  });
}

export function usePreviewImages<TData = PreviewImageResult[]>(
  logId: Log["id"] | null,
  options?: StrictOmit<
    UseQueryOptions<
      PreviewImageResult[],
      unknown,
      TData,
      LogKeys["previewImages"]
    >,
    "staleTime" | "cacheTime"
  >,
): SimpleQueryResult<TData> {
  const logQuery = useLog(logId, {
    select(response): ReadonlyArray<string> {
      const base64EncodedImages: unknown = (response.data.context as any)
        ?.studio?.thumbnails;

      if (base64EncodedImages == null) {
        return [];
      }

      const areImagesAvailable =
        Array.isArray(base64EncodedImages) &&
        base64EncodedImages.every(
          (encodedImage): encodedImage is string =>
            typeof encodedImage === "string",
        );

      if (!areImagesAvailable) {
        throw new Error("No images");
      }

      return base64EncodedImages.slice(0, 3);
    },
  });

  const base64EncodedImages = logQuery.data;
  const enabled = base64EncodedImages != null;

  const previewImagesQuery = useQuery({
    queryKey: logKeys.previewImages(logId, base64EncodedImages),
    async queryFn() {
      invariant(enabled, "No images to fetch");

      const fetchedImagePromises = await Promise.allSettled(
        base64EncodedImages.map(convertBase64ImageToBlob),
      );

      const previews: PreviewImageResult[] = fetchedImagePromises.map(
        (promiseResult) => {
          if (promiseResult.status === "fulfilled") {
            return { success: true, blob: promiseResult.value };
          } else {
            return { success: false, blob: null };
          }
        },
      );

      return previews;
    },
    ...options,
    staleTime: Infinity,
    cacheTime: secondsToMilliseconds(20),
    enabled: mergeEnabledOption(options, enabled),
  });

  return combineQueries({
    queries: [logQuery, previewImagesQuery],
    transform([, previewImages]) {
      return previewImages;
    },
  });
}

export function useUploadPreviewImage(
  logId: Log["id"],
): UseMutationResult<void, unknown, PreviewImageUpload> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({ image, index, deleteExisting }) {
      const objectKeySuffix = `preview_${index}.webp`;

      if (deleteExisting) {
        try {
          await logApi.deleteLogObject({ logId, objectKey: objectKeySuffix });
        } catch (e) {
          // The `deleteExisting` flag is a "best guess" at whether an existing
          // object should be deleted. If there was some error when fetching
          // the thumbnail, there's no way to know if the slot is taken so
          // a delete attempt is made. A 404 is considered fine here since
          // it just means there wasn't anything to delete.
          if (!(e instanceof ResponseError) || e.response.status !== 404) {
            throw e;
          }
        }
      }

      const {
        data: { key: objectKey },
      } = await logApi.createLogObject({
        logId,
        objectCreateRequest: {
          key: objectKeySuffix,
        },
      });

      await new MultipartUploader({
        blob: image,
        createPartPresignedUrl: createObjectPartPresignedUrlFactory(
          logApi,
          logId,
          objectKey,
        ),
        async onAbort() {
          await logApi.deleteLogObject({ logId, objectKey });
        },
      }).start();

      await logApi.updateLogObject({
        logId,
        objectKey,
        objectUpdateRequest: {
          uploadState: "complete",
        },
      });
    },
    onSuccess() {
      return queryClient.invalidateQueries(logKeys.allPreviewImages(logId));
    },
  });
}

// Utilities

export function createObjectPartPresignedUrlFactory(
  logApi: LogApi,
  logId: Log["id"],
  objectKey: CloudObject["key"],
) {
  return async function createObjectPartPresignedUrl(
    request: ObjectPartCreateRequest,
    signal: AbortSignal,
  ): Promise<string> {
    const {
      data: { presignedUrl },
    } = await logApi.createLogObjectPart(
      {
        logId,
        objectKey,
        objectPartCreateRequest: request,
      },
      { signal },
    );

    // Though the types say the URL could be `null`, it shouldn't happen
    // in practice. In any case, if it's `null` the upload can't proceed.
    invariant(presignedUrl !== null, "Presigned URL should not be null");

    return presignedUrl;
  };
}
