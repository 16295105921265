import type { DataStore } from "~/dsm";
import { Configuration } from "~/lqs";

const API_ENDPOINT = "https://api.logqs.com/apps/lqs";

// This is technically an API key since the back-end's router (currently)
// requires an "Authorization" header even though the DataStore itself doesn't
// require authentication. This key isn't sensitive so it's fine to be
// hard-coded for this demo.
const DUMMY_API_KEY =
  "NGZhOTNjM2ItZjE2Mi00Y2JiLTgxYjItNDY3MmQyNWMxNDRhOnNvbWVzZWNyZXR2YWx1ZQ==";

export function createLqsSdkConfiguration(
  dataStoreId: DataStore["id"],
): Configuration {
  const basePath = `${API_ENDPOINT}/${dataStoreId}`;

  return new Configuration({
    basePath,
    accessToken: () => DUMMY_API_KEY,
  });
}
