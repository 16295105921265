import React from "react";
import { Button, styled, Tooltip, Typography } from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import { BreakableText } from "~/components/BreakableText";
import { Center } from "~/components/Center";
import { Error } from "~/components/Error";
import { useBlobSource } from "~/hooks";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout } from "../../components";
import { useUpdatePanelBuffering } from "../../hooks";
import {
  InferenceError,
  InferenceResultsVisualization,
  searchForInferenceTopic,
} from "../../inference";
import type { InitializedPanelNode } from "../../panels";
import {
  useCalculateFrameTimestamp,
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../../playback";
import { ColorMap, createColorMapFilterId } from "./color-map";
import { useImageFrames } from "./use-image-frames";
import { useImageSliderController } from "./use-image-slider-controller";
import { getTransformProps } from "./utils";

const ImageContainer = styled("div")({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  containerType: "size",
  "& [data-content]": {
    display: "block",
    width: "100cqw",
    height: "100cqh",
    position: "absolute",
    top: "50%",
    left: "50%",
    '&[data-sideways="true"]': {
      // `object-fit: contain` is applied prior to transformations, so rotating
      // an image sideways doesn't give the desired effect. Consequently, when
      // the image is rotated, it should be sized according to the container's
      // swapped dimensions (i.e. its height is the container's width) to cause
      // the browser to apply `object-fit: contain` according to the dimensions
      // it'll have when it's rotated. Thankfully container queries and the new
      // container query units exist or a resize observer would be necessary.
      width: "100cqh",
      height: "100cqw",
    },
  },
  "& img[data-content]": {
    objectFit: "contain",
  },
});

export function ImageVisualization({
  panel,
  topic,
  playerTopics,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
  playerTopics: ReadonlyArray<Topic>;
}) {
  const inferenceTopicSearchResult = searchForInferenceTopic(
    panel,
    playerTopics,
  );

  const { snapshot: imageFramesSnapshot, isPlaceholder } = useImageFrames({
    topic,
    inferenceTopic: inferenceTopicSearchResult.topic,
  });

  const playbackSource = useLoadedPlaybackSource();

  const playerActions = usePlayerActions();

  function handleClearInferenceTopic(): void {
    playerActions.setInferenceTopic(panel, null);
  }

  const calculateFrameTimestamp = useCalculateFrameTimestamp();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();
  function formatElapsedTime(timestamp: bigint): string {
    return formatPlaybackTimestamp(
      Number(
        calculateFrameTimestamp(playbackSource.timestamp) -
          calculateFrameTimestamp(timestamp),
      ),
    );
  }

  useUpdatePanelBuffering(
    imageFramesSnapshot.status === "pending" || isPlaceholder,
  );

  const imageFrame = imageFramesSnapshot.value?.current;

  const imgSrcRef = useBlobSource(imageFrame?.data);

  const imageSliderController = useImageSliderController({
    panel,
    imageFrame,
  });

  let rootContent;
  if (imageFramesSnapshot.status === "pending") {
    rootContent = <LoadingFeedback description="images" />;
  } else if (imageFramesSnapshot.status === "rejected") {
    if (imageFramesSnapshot.reason instanceof InferenceError) {
      rootContent = (
        <Error>
          <Typography variant="h5" component="p" paragraph>
            Couldn't get inference results for{" "}
            {inferenceTopicSearchResult.status === "found" ? (
              <code>
                <BreakableText separator={/(\/)/}>
                  {inferenceTopicSearchResult.topic.name}
                </BreakableText>
              </code>
            ) : (
              "topic"
            )}
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClearInferenceTopic}
          >
            Clear Inference Topic
          </Button>
        </Error>
      );
    } else {
      rootContent = (
        <Error>
          <Typography variant="h5" component="p">
            An error occurred. Unable to get images
          </Typography>
        </Error>
      );
    }
  } else {
    const {
      value: { current: imageFrame, nextTimestamp },
    } = imageFramesSnapshot;

    let imageContent;
    if (imageFrame === null) {
      const showFirstRecordButton = nextTimestamp !== null;

      imageContent = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p" paragraph>
            No recent image
          </Typography>
          {showFirstRecordButton && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                playerActions.seek(calculateFrameTimestamp(nextTimestamp))
              }
            >
              Skip to First Image
            </Button>
          )}
        </Center>
      );
    } else {
      imageContent = (
        <>
          <img
            id={imageSliderController.baseImageId}
            // When listening for pointer events for the image slider feature,
            // don't let this image be dragged
            draggable={imageSliderController.enabled ? "false" : "true"}
            ref={imgSrcRef}
            {...getTransformProps({
              rotationDeg: panel.imageRotationDeg,
              flipDirection: panel.imageFlipDirection,
              filter:
                panel.colorizeImage &&
                `url(#${createColorMapFilterId(panel.id)})`,
            })}
          />
          {(panel.colorizeImage || panel.colorizeInferenceImage) && (
            <ColorMap panelId={panel.id} />
          )}
          <InferenceResultsVisualization
            panel={panel}
            imageFrame={imageFrame}
          />
          {imageFrame?.isStale && (
            <Tooltip
              title={`No new image for ${formatElapsedTime(
                imageFrame.timestamp,
              )}`}
              placement="left"
            >
              <TimerSand
                sx={{
                  position: "absolute",
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(1),
                }}
              />
            </Tooltip>
          )}
          {isPlaceholder && <LoadingFeedback description="images" />}
        </>
      );
    }

    rootContent = (
      <ImageContainer {...imageSliderController.pointerContainerProps}>
        {imageContent}
      </ImageContainer>
    );
  }

  return <PanelLayout>{rootContent}</PanelLayout>;
}
