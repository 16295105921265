import { ErrorOutline } from "@mui/icons-material";
import { CircularProgress, Stack, styled, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { Details } from "~/components/Details";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import { JsonField } from "~/components/DetailsCards/JsonField";
import { Error } from "~/components/Error";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import {
  OBJECT_KEY_DELIMITER,
  ObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { Record } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteRecord,
  useRecord,
  useUpdateRecord,
} from "~/lqs";
import {
  makeEditRecordLocation,
  makeLogObjectsLocation,
  makeRecordsLocation,
  useRecordParams,
} from "~/paths";
import { selectData } from "~/utils";
import { LogObjectTable } from "../../logs/components/LogObjectTable";
import { editRecordSchema } from "../schemas";

export function RecordDetails() {
  const { topicId, timestamp } = useRecordParams();

  const query = useRecord(topicId, timestamp, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const dataSection = <DataSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedObjects query={query} />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="record"
      query={query}
      updateMutation={useUpdateRecord(topicId, timestamp)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="record"
      query={query}
      editLocation={makeEditRecordLocation({ topicId, timestamp })}
      deleteMutation={useDeleteRecord(topicId, timestamp)}
      getReadableName={(record) => String(record.timestamp)}
      listLocation={makeRecordsLocation({ topicId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {dataSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {dataSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Record> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "timestamp" },
          {
            dataType: "foreign-key",
            resourceType: "topic",
            accessor: "topicId",
          },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          {
            dataType: "foreign-key",
            resourceType: "ingestion",
            accessor: "ingestionId",
          },
          { dataType: "bytes", accessor: "dataOffset" },
          { dataType: "bytes", accessor: "dataLength" },
          { dataType: "text", accessor: "chunkCompression" },
          { dataType: "bytes", accessor: "chunkOffset" },
          { dataType: "bytes", accessor: "chunkLength" },
          { dataType: "text", accessor: "source" },
        ]}
      />
    </Card>
  );
}

const ErrorContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "icon message"
    "icon error"
  `,
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto minmax(0, 1fr)",
}));

function DataSection({ query }: { query: UseQueryResult<Record> }) {
  const hasError = query.data?.error != null;

  return (
    <Card title="Data" error={hasError}>
      {hasError ? (
        <ErrorContent>
          <ErrorOutline
            sx={{
              gridArea: "icon",
              fontSize: "3rem",
            }}
            color="error"
          />
          <Typography paragraph sx={{ gridArea: "message" }}>
            The record failed to process
          </Typography>
          <Details sx={{ gridArea: "error" }}>
            <Details.Summary>Expand to see error</Details.Summary>
            <JsonField value={query.data!.error} />
          </Details>
        </ErrorContent>
      ) : (
        <LqsResourceFields
          query={query}
          fields={[{ dataType: "json", accessor: "queryData" }]}
        />
      )}
    </Card>
  );
}

function RelatedObjects({ query }: { query: UseQueryResult<Record> }) {
  return (
    <QueryRenderer
      query={query}
      loading={
        <Card>
          <Center sx={{ height: 350 }}>
            <CircularProgress />
          </Center>
        </Card>
      }
      error={
        <Card>
          <Error>
            <Typography variant="h4" component="p">
              Couldn't load objects
            </Typography>
          </Error>
        </Card>
      }
      success={(record) => {
        const recordPrefix = [
          "topics",
          record.topicId,
          "records",
          record.timestamp,
          "",
        ].join(OBJECT_KEY_DELIMITER);

        return (
          <RelatedResource
            text="Objects"
            to={makeLogObjectsLocation(record.logId, {
              directory: recordPrefix,
            })}
            table={
              <ObjectSearchRequestProvider embedded>
                <LogObjectTable
                  logId={record.logId}
                  homeName={String(record.timestamp)}
                  subResourcePrefix={recordPrefix}
                />
              </ObjectSearchRequestProvider>
            }
          />
        );
      }}
    />
  );
}

function InfoSection({ query }: { query: UseQueryResult<Record> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "multiline"]} />}
        success={(record) => <InfoSectionImpl record={record} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ record }: { record: Record }) {
  const updateRecord = useUpdateRecord(record.topicId, record.timestamp);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editRecordSchema.pick({ note: true, context: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(record, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateRecord.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Record updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update record", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateRecord.isLoading}
        submitText="Save Changes"
        disabled={record.locked}
      >
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
      {record.locked && (
        <Typography variant="body2">
          Unlock this record to make updates.
        </Typography>
      )}
    </>
  );
}
