import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Digestion, LqsColumn } from "~/lqs";
import { LqsResourceTable, useDigestions } from "~/lqs";
import {
  DataStoreLink,
  makeDigestionLocation,
  makeNewDigestionLocation,
} from "~/paths";
import { listDigestionsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Digestion>> = [
  {
    header: "Digestion",
    sortKey: "name",
    renderCell(digestion) {
      return (
        <TableCell>
          {digestion.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeDigestionLocation({ digestionId: digestion.id })}
          >
            <Typography variant="body2">{digestion.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useDigestionSearchRequest, DigestionSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listDigestionsSchema,
  });

export function DigestionTable() {
  const [request, setRequest] = useDigestionSearchRequest();

  const searchQuery = useDigestions(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="digestion"
      resourceCreateLocation={makeNewDigestionLocation()}
      getRowKey={(digestion) => digestion.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
