import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Group, LqsColumn } from "~/lqs";
import { LqsResourceTable, useGroups } from "~/lqs";
import {
  DataStoreLink,
  makeGroupLocation,
  makeNewGroupLocation,
} from "~/paths";
import { listGroupsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Group>> = [
  {
    header: "Group",
    sortKey: "name",
    renderCell(group) {
      return (
        <TableCell>
          {group.name}
          <Link
            component={DataStoreLink}
            to={makeGroupLocation({ groupId: group.id })}
          >
            <Typography variant="body2">{group.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "defaultWorkflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useGroupSearchRequest, GroupSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listGroupsSchema,
  });

export function GroupTable() {
  const [request, setRequest] = useGroupSearchRequest();

  const searchQuery = useGroups(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="group"
      resourceCreateLocation={makeNewGroupLocation()}
      getRowKey={(group) => group.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
