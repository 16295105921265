import { useQuery } from "@tanstack/react-query";
import type { ListTopicsRequest } from "~/lqs";
import { circumventPagination, useDataStoreClients } from "~/lqs";
import { usePlayerParams } from "./use-player-params";

export function usePlayerTopics() {
  const { logId } = usePlayerParams();

  const { topicApi } = useDataStoreClients();

  const request: ListTopicsRequest = {
    logId,
    sort: "asc",
    order: "name",
  };

  return useQuery({
    queryKey: ["player-topics", request],
    async queryFn({ signal }) {
      const response = await circumventPagination(
        topicApi.listTopics.bind(topicApi),
        100,
        request,
        { signal },
      );

      return response.data;
    },
    enabled: logId != null,
  });
}
