import React from "react";
import { Error } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { z } from "zod";
import { CheckboxField, TextField, useStudioForm } from "~/components/Form";
import { Pagination } from "~/components/Pagination";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useSearchRequest } from "~/components/Table";
import {
  filterCheckbox,
  filterText,
  selectCountableListResponse,
} from "~/domain/common";
import { pick } from "~/lib/std";
import { useLogs } from "~/lqs";
import { DataStoreLink, makeLogsMapLocation } from "~/paths";
import { pluralize } from "~/utils";
import { LogThumbnailCard } from "./LogThumbnailCard";

const LIMIT_OPTIONS = [15, 25, 50];

const SortOptions = {
  Newest: "newest",
  Oldest: "oldest",
} as const;

const filterSchema = z.object({
  search: filterText,
  playable: filterCheckbox(true).catch(true),
});

const requestSchema = filterSchema
  .extend({
    limit: z.coerce
      .number()
      .refine((arg) => LIMIT_OPTIONS.includes(arg))
      .catch(LIMIT_OPTIONS[0]),
    offset: z.coerce.number().int().nonnegative().catch(0),
    sort: z.nativeEnum(SortOptions).catch(SortOptions.Newest),
  })
  .transform((arg) => {
    // Offset should be multiple of limit
    const isValidOffset = arg.offset % arg.limit === 0;

    return {
      ...arg,
      offset: isValidOffset ? arg.offset : 0,
    };
  });

export function LogThumbnails() {
  const [request, setRequest] = useSearchRequest(requestSchema);

  const sortOptions =
    request.sort === "newest"
      ? { sort: "desc", order: "start_time" }
      : { sort: "asc", order: "start_time" };

  const logsQuery = useLogs(
    {
      nameLike: request.search,
      startTimeNull: request.playable ? false : undefined,
      limit: request.limit,
      offset: request.offset,
      ...sortOptions,
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const { control, handleSubmit, watch } = useStudioForm({
    schema: filterSchema,
    values: pick(request, filterSchema.keyof().options),
    onSubmit: setRequest,
  });

  const searchValue = watch("search");

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Stack spacing={2} component="form" onSubmit={handleSubmit}>
            <TextField control={control} name="search" label="Log name" />
            <CheckboxField
              control={control}
              name="playable"
              label="Playable logs only"
            />
            <Stack direction="row" spacing={2}>
              <Button type="submit" color="primary" variant="contained">
                Search
              </Button>
              <Button
                component={DataStoreLink}
                to={makeLogsMapLocation({ search: searchValue })}
                color="primary"
                variant="outlined"
              >
                Show in Map
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            {logsQuery.isLoading ? (
              <Typography>Fetching logs...</Typography>
            ) : logsQuery.isError ? (
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Error color="error" />
                <Typography>Unable to perform search</Typography>
              </Stack>
            ) : logsQuery.isRefetching ? (
              <Typography>Searching...</Typography>
            ) : (
              <Typography>{pluralize(logsQuery.data.count, "log")}</Typography>
            )}
            <MuiTextField
              select
              size="small"
              label="Sort by"
              value={request.sort}
              onChange={(e) => setRequest({ sort: e.target.value as any })}
              sx={{ ml: "auto", width: "18ch" }}
            >
              <MenuItem value={SortOptions.Newest}>Newest logs</MenuItem>
              <MenuItem value={SortOptions.Oldest}>Oldest logs</MenuItem>
            </MuiTextField>
            <MuiTextField
              select
              size="small"
              label="Results per page"
              value={request.limit}
              onChange={(e) => setRequest({ limit: Number(e.target.value) })}
              sx={{ ml: 2, width: "15ch" }}
            >
              {LIMIT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </MuiTextField>
          </Stack>
          <Box sx={{ position: "relative", mt: 2 }}>
            <Divider />
            {logsQuery.isFetching && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
          </Box>
          <QueryRenderer
            query={logsQuery}
            error={
              <Typography
                variant="h4"
                component="p"
                sx={{ textAlign: "center", my: 4 }}
              >
                An error occurred searching for logs
              </Typography>
            }
            success={(response) => {
              if (response.count === 0) {
                return (
                  <Typography
                    variant="h4"
                    component="p"
                    sx={{ textAlign: "center", my: 4 }}
                  >
                    No logs matched the filters
                  </Typography>
                );
              } else {
                return (
                  <Grid container spacing={3} sx={{ mt: 0, mb: 3 }}>
                    {response.data.map((log) => (
                      <Grid
                        key={log.id}
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "stretch" }}
                      >
                        <LogThumbnailCard log={log} />
                      </Grid>
                    ))}
                  </Grid>
                );
              }
            }}
          />
          {logsQuery.isSuccess && (
            <>
              <Divider sx={{ mb: 2 }} />
              <Pagination
                count={logsQuery.data.count}
                limit={request.limit}
                offset={request.offset}
                onChange={(offset) => {
                  setRequest({ offset });
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
}
