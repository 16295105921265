import React, { useRef } from "react";
import { Search } from "@mui/icons-material";
import type { ButtonBaseActions } from "@mui/material";
import {
  LinearProgress,
  IconButton,
  InputAdornment,
  Box,
  List,
  Paper,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import useResizeObserver from "use-resize-observer";
import { z } from "zod";
import { Center } from "~/components/Center";
import { Error } from "~/components/Error";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { filterText } from "~/domain/common";
import { useTypedSearchParams } from "~/hooks";
import type { Log } from "~/lqs";
import { useLogs } from "~/lqs";
import { LogThumbnailCard } from "./LogThumbnailCard";
import { calculateLogMarkers } from "./utils";

const LazyMapView = React.lazy(() => import("./LazyMapView"));

const filterSchema = z.object({
  search: filterText,
});

export function LogMap() {
  const [filters, setFilters] = useTypedSearchParams(filterSchema);

  const { ref, width, height } = useResizeObserver();

  const searchQuery = useLogs(
    {
      limit: 500,
      sort: "desc",
      order: "start_time",
      nameLike: filters.search,
      startTimeNull: false,
      contextFilter: JSON.stringify([
        {
          var: "sample_coordinates.longitude",
          op: "gt",
          val: -180,
        },
      ]),
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select(response) {
        return {
          logs: response.data,
          logMarkers: calculateLogMarkers(response.data),
        };
      },
    },
  );

  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: filters,
    onSubmit: setFilters,
  });

  const { makeRefCallback, focusLog } = useLogFocus();

  return (
    <QueryRenderer
      query={searchQuery}
      loading={<Loading type="circular" />}
      error={
        <Error>
          <Typography variant="h5" component="p">
            Error fetching logs
          </Typography>
        </Error>
      }
      success={(response) => (
        <Box sx={{ height: 1, width: 1, position: "relative" }} ref={ref}>
          <ErrorBoundary
            fallback={
              <Center>
                <Typography variant="h5" component="p">
                  Error initializing map
                </Typography>
              </Center>
            }
          >
            <React.Suspense fallback={null}>
              <LazyMapView
                width={width}
                height={height}
                logMarkers={response.logMarkers}
                onLogClick={focusLog}
              />
            </React.Suspense>
          </ErrorBoundary>
          <Box
            sx={{
              position: "absolute",
              inset: (theme) => theme.spacing(1),
              pointerEvents: "none",
              p: 1,
            }}
          >
            <Paper
              component={Stack}
              sx={{
                width: 450,
                maxWidth: "100%",
                maxHeight: 1,
                pointerEvents: "auto",
                p: 2,
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ flex: "none", position: "relative" }}
              >
                <TextField
                  control={control}
                  name="search"
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title="Search">
                        <IconButton type="submit" edge="end">
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
                {searchQuery.isRefetching && (
                  <LinearProgress
                    sx={{ position: "absolute", bottom: 0, width: 1 }}
                  />
                )}
              </Box>
              <List
                component="div"
                // `data-scroll-root` used for thumbnail cards'
                // IntersectionObservers
                data-scroll-root=""
                disablePadding
                sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}
              >
                {response.logs.map((log) => (
                  <LogThumbnailCard
                    key={log.id}
                    log={log}
                    actionRef={makeRefCallback(log.id)}
                  />
                ))}
              </List>
            </Paper>
          </Box>
        </Box>
      )}
    />
  );
}

function useLogFocus() {
  const mapRef = useRef(new Map<Log["id"], ButtonBaseActions>());

  return {
    makeRefCallback(logId: Log["id"]): React.RefCallback<ButtonBaseActions> {
      return function refCallback(actions) {
        if (actions === null) {
          mapRef.current.delete(logId);
        } else {
          mapRef.current.set(logId, actions);
        }
      };
    },
    focusLog(logId: Log["id"]) {
      mapRef.current.get(logId)?.focusVisible();
    },
  };
}
