import React from "react";
import type { Root } from "react-dom/client";
import { App } from "./app";

export function start(root: Root): void {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
