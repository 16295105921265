import {
  LogPlaybackSourceProvider,
  PanelLayoutProvider,
  PlaybackSettingsProvider,
  PlaybackTimerProvider,
  Player,
  RecordStoreProvider,
  TagStoreProvider,
  ThreeDControlsProvider,
} from "~/player";
import { useInitialLayoutProfile } from "./hooks";

export function DemoAppPage() {
  const initialLayoutProfile = useInitialLayoutProfile();

  return (
    <PanelLayoutProvider initialLayout={initialLayoutProfile?.layout}>
      <PlaybackSettingsProvider>
        <LogPlaybackSourceProvider>
          <RecordStoreProvider>
            <TagStoreProvider>
              <PlaybackTimerProvider>
                <ThreeDControlsProvider>
                  <Player />
                </ThreeDControlsProvider>
              </PlaybackTimerProvider>
            </TagStoreProvider>
          </RecordStoreProvider>
        </LogPlaybackSourceProvider>
      </PlaybackSettingsProvider>
    </PanelLayoutProvider>
  );
}
