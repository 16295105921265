import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Role } from "~/lqs";
import { LqsResourceTable, useRoles } from "~/lqs";
import { DataStoreLink, makeNewRoleLocation, makeRoleLocation } from "~/paths";
import { listRolesSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Role>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(role) {
      return (
        <TableCell>
          {role.name}
          <Link
            component={DataStoreLink}
            to={makeRoleLocation({ roleId: role.id })}
          >
            <Typography variant="body2">{role.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useRoleSearchRequest, RoleSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listRolesSchema,
  });

export function RoleTable() {
  const [request, setRequest] = useRoleSearchRequest();

  const searchQuery = useRoles(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="role"
      resourceCreateLocation={makeNewRoleLocation()}
      getRowKey={(role) => role.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
