import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { DigestionTopic, LqsColumn } from "~/lqs";
import { LqsResourceTable, useDigestionTopics } from "~/lqs";
import {
  DataStoreLink,
  makeDigestionTopicLocation,
  makeNewDigestionTopicLocation,
  useDigestionParams,
} from "~/paths";
import { listDigestionTopicsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<DigestionTopic>> = [
  {
    header: "Digestion Topic ID",
    renderCell(topic) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeDigestionTopicLocation({
              digestionId: topic.digestionId,
              topicId: topic.id,
            })}
          >
            <Typography variant="body2">{topic.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "frequency",
    dataType: "number",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [
  useDigestionTopicSearchRequest,
  DigestionTopicSearchRequestProvider,
] = createSearchRequestProvider({
  columns,
  filterSchema: listDigestionTopicsSchema,
});

export function DigestionTopicTable() {
  const { digestionId } = useDigestionParams();

  const [request, setRequest] = useDigestionTopicSearchRequest();

  const searchQuery = useDigestionTopics(
    digestionId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="topic"
      resourceCreateLocation={makeNewDigestionTopicLocation({
        digestionId,
      })}
      getRowKey={(topic) => topic.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
