import type { StrictOmit } from "ts-essentials";
import { z } from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterText,
  filterUuid,
  optionalBigInt,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredText,
  requiredUuid,
} from "~/domain/common";
import type {
  ListLogsRequest,
  ListQueriesRequest,
  ListTagsRequest,
  LogCreateRequest,
  LogUpdateRequest,
  QueryCreateRequest,
  QueryUpdateRequest,
  TagCreateRequest,
  TagUpdateRequest,
} from "~/lqs";

export const listLogsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  groupId: filterUuid,
  locked: filterBoolean,
  noteLike: filterText,
  defaultWorkflowId: filterUuid,
  defaultWorkflowIdNull: filterBoolean,
  timeAdjustmentGte: filterBigInt,
  timeAdjustmentLte: filterBigInt,
  timeAdjustmentNull: filterBoolean,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  recordSizeGte: filterNumber,
  recordSizeLte: filterNumber,
  recordCountGte: filterNumber,
  recordCountLte: filterNumber,
  objectSizeGte: filterNumber,
  objectSizeLte: filterNumber,
  objectCountGte: filterNumber,
  objectCountLte: filterNumber,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListLogsRequest, "contextFilter" | "includeCount">
>);

export const createLogSchema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  defaultWorkflowId: optionalUuid,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<LogCreateRequest>);

export const editLogSchema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  defaultWorkflowId: optionalUuid,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<LogUpdateRequest>);

export const EDITABLE_LOG_FIELDS = editLogSchema.keyof().options;

export const listTagsSchema = z.object({
  id: filterUuid,
  labelId: filterUuid,
  topicId: filterUuid,
  note: filterText,
  noteLike: filterText,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListTagsRequest, "logId" | "contextFilter" | "includeCount">
>);

export const createTagSchema = z.object({
  labelId: requiredUuid,
  topicId: optionalUuid,
  note: optionalText,
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  context: optionalObject,
} satisfies SchemaShape<TagCreateRequest>);

export const editTagSchema = z.object({
  labelId: requiredUuid,
  topicId: optionalUuid,
  note: optionalText,
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  context: optionalObject,
} satisfies SchemaShape<TagUpdateRequest>);

export const EDITABLE_TAG_FIELDS = editTagSchema.keyof().options;

export const listLogQueriesSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  noteLike: filterText,
  statement: filterText,
  statementLike: filterText,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListQueriesRequest,
    "logId" | "workflowContextFilter" | "contextFilter" | "includeCount"
  >
>);

export const createLogQuerySchema = z.object({
  name: optionalText,
  statement: optionalText,
  parameters: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<QueryCreateRequest>);

export const editLogQuerySchema = z.object({
  name: optionalText,
  statement: optionalText,
  parameters: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<QueryUpdateRequest>);

export const EDITABLE_LOG_QUERY_FIELDS = editLogQuerySchema.keyof().options;
