import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Workflow } from "~/lqs";
import { LqsResourceTable, useWorkflows } from "~/lqs";
import {
  DataStoreLink,
  makeNewWorkflowLocation,
  makeWorkflowLocation,
} from "~/paths";
import { listWorkflowsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Workflow>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(workflow) {
      return (
        <TableCell>
          {workflow.name}
          <Link
            component={DataStoreLink}
            to={makeWorkflowLocation({ workflowId: workflow.id })}
          >
            <Typography variant="body2">{workflow.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useWorkflowSearchRequest, WorkflowSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listWorkflowsSchema,
  });

export function WorkflowTable() {
  const [request, setRequest] = useWorkflowSearchRequest();

  const searchQuery = useWorkflows(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="workflow"
      resourceCreateLocation={makeNewWorkflowLocation()}
      getRowKey={(workflow) => workflow.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
