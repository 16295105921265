import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Tag } from "~/lqs";
import { LqsResourceTable, useTags } from "~/lqs";
import {
  DataStoreLink,
  makeNewTagLocation,
  makeTagLocation,
  useLogParams,
} from "~/paths";
import { listTagsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Tag>> = [
  {
    header: "ID",
    renderCell(tag) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeTagLocation({ logId: tag.logId, tagId: tag.id })}
          >
            <Typography variant="body2">{tag.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "labelId",
    dataType: "foreign-key",
    resourceType: "label",
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useTagSearchRequest, TagSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listTagsSchema,
  });

export function TagTable() {
  const { logId } = useLogParams();

  const [request, setRequest] = useTagSearchRequest();

  const searchQuery = useTags(logId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="tag"
      resourceCreateLocation={makeNewTagLocation({ logId })}
      getRowKey={(tag) => tag.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
