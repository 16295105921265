import React, { useMemo } from "react";
import type { PaletteMode as ColorScheme } from "@mui/material";
import { createSafeContext } from "~/contexts";
import { useLocalStorage } from "~/hooks";

export type { ColorScheme };

const COLOR_SCHEME_STORAGE_KEY = "settings.color-scheme";

const DEFAULT_COLOR_SCHEME: ColorScheme = "dark";

export type BytesFormat = "plain" | "compact";

const BYTES_FORMAT_STORAGE_KEY = "settings.bytes-format";

const DEFAULT_BYTES_FORMAT: BytesFormat = "compact";

interface SettingsContextValue {
  colorScheme: ColorScheme;
  setColorScheme: (colorScheme: ColorScheme) => void;
  bytesFormat: BytesFormat;
  setBytesFormat: (bytesFormat: BytesFormat) => void;
}

export const [useSettings, SettingsContext] =
  createSafeContext<SettingsContextValue>("Settings");

interface SettingsProviderProps {
  children: React.ReactNode;
}

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>(
    COLOR_SCHEME_STORAGE_KEY,
    DEFAULT_COLOR_SCHEME,
  );

  const [bytesFormat, setBytesFormat] = useLocalStorage<BytesFormat>(
    BYTES_FORMAT_STORAGE_KEY,
    DEFAULT_BYTES_FORMAT,
  );

  const value = useMemo(
    () => ({
      colorScheme,
      setColorScheme,
      bytesFormat,
      setBytesFormat,
    }),
    [colorScheme, setColorScheme, bytesFormat, setBytesFormat],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
