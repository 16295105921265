import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Hook, LqsColumn } from "~/lqs";
import { LqsResourceTable, useWorkflowHooks } from "~/lqs";
import {
  DataStoreLink,
  makeHookLocation,
  makeNewHookLocation,
  useWorkflowParams,
} from "~/paths";
import { listWorkflowHooksSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Hook>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(workflowHook) {
      return (
        <TableCell>
          {workflowHook.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeHookLocation({
              workflowId: workflowHook.workflowId,
              hookId: workflowHook.id,
            })}
          >
            <Typography variant="body2">{workflowHook.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "triggerProcess",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "triggerState",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "uri",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useWorkflowHookSearchRequest, WorkflowHookSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listWorkflowHooksSchema,
  });

export function WorkflowHookTable() {
  const { workflowId } = useWorkflowParams();

  const [request, setRequest] = useWorkflowHookSearchRequest();

  const searchQuery = useWorkflowHooks(
    workflowId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="hook"
      resourceCreateLocation={makeNewHookLocation({ workflowId })}
      getRowKey={(workflowHook) => workflowHook.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
