import React from "react";
import { styled } from "@mui/material";
import { nanosecondsToSeconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import { usePlayerActions } from "../actions";
import { usePlaybackSource } from "../playback";

const Root = styled("div")<{ $leftOffsetPercentage: number }>(
  ({ theme, $leftOffsetPercentage }) => ({
    position: "absolute",
    top: 0,
    left: `${$leftOffsetPercentage}%`,
    translate: "-50% calc(-50% - 2px)",
    cursor: "pointer",

    // Marker stem
    width: 4,
    height: 20,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.secondary.main,

    // Grow slightly on hover and appear over top of any neighboring marks
    "&:hover": {
      zIndex: 1,
      scale: "1.1",
      transition: theme.transitions.create(["scale"]),
    },

    // Increase area to help clicking and hovering
    "&::before": {
      content: '" "',
      position: "absolute",
      top: -6,
      bottom: -1,
      left: -2,
      right: -2,
      zIndex: -1,
    },

    // Marker circle
    "&::after": {
      content: '" "',
      position: "absolute",
      top: 0,
      left: "50%",
      width: 10,
      height: 10,
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
    },
  }),
);

interface TagMarkerProps {
  timestamp: bigint;
}

export function TagMarker({ timestamp }: TagMarkerProps) {
  const playbackSource = usePlaybackSource();
  const playerActions = usePlayerActions();

  invariant(
    !playbackSource.isLoading,
    "Can't render a marker without a loaded playback source",
  );

  const leftOffsetPercentage = calculateLeftOffsetPercentage(
    timestamp - playbackSource.bounds.startTime,
    playbackSource.bounds.endTime - playbackSource.bounds.startTime,
  );

  function handleClick() {
    playerActions.seek(timestamp);
  }

  return (
    <Root $leftOffsetPercentage={leftOffsetPercentage} onClick={handleClick} />
  );
}

function calculateLeftOffsetPercentage(
  tagTimestamp: bigint,
  duration: bigint,
): number {
  return (
    (nanosecondsToSeconds(tagTimestamp) / nanosecondsToSeconds(duration)) * 100
  );
}
