import React, {
  memo,
  useDeferredValue,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import {
  alpha,
  Button,
  ButtonGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { TreeItem, treeItemClasses, TreeView } from "@mui/x-tree-view";
import { matchSorter } from "match-sorter";
import type { StrictOmit } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import { compact } from "~/lib/std";
import type { Topic } from "~/lqs";
import { getEventHandlerProps, pluralize, traverseTree } from "~/utils";
import { BreakableText } from "./BreakableText";

type NodeId = string;

interface TreeActions {
  expandAll: () => void;
  collapseAll: () => void;
}

interface TopicNode {
  name: Topic["name"];
  nodeId: NodeId;
  typeName?: Topic["typeName"];
  children: Array<TopicNode>;
}

interface BaseTopicTreeProps {
  topics: ReadonlyArray<Topic>;
  disabled?: boolean;
}

interface ReadonlyTopicTreeProps extends BaseTopicTreeProps {
  multiple?: false;
  selected?: never;
  onSelect: (newValue: Topic) => void;
}

interface MultiTopicTreeProps extends BaseTopicTreeProps {
  multiple: true;
  selected: Array<Topic>;
  onSelect: (newValue: ReadonlyArray<Topic>) => void;
}

type TopicTreeProps = ReadonlyTopicTreeProps | MultiTopicTreeProps;

export function TopicTree({ topics, ...rest }: TopicTreeProps) {
  // TS doesn't like destructuring these above then trying to individually
  // pass them to <TreeContent /> below
  const { disabled, multiple, selected, onSelect } = rest;

  const [filter, setFilter] = useState("");
  const deferredFilter = useDeferredValue(filter);

  const treeActionsRef = useRef<TreeActions>(null);

  const filteredTopics = useMemo(() => {
    if (deferredFilter === "") {
      return topics;
    }

    return matchSorter(topics, deferredFilter, {
      keys: ["name", (topic) => topic.typeName ?? []],
      threshold: matchSorter.rankings.CONTAINS,
      sorter: (matchItems) => matchItems,
    });
  }, [topics, deferredFilter]);

  let helperText;
  if (deferredFilter === "") {
    helperText = `Showing ${pluralize(filteredTopics.length, "topic")}`;
  } else {
    helperText = `${pluralize(filteredTopics.length, "topic")} matched`;
  }

  if (multiple) {
    const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));
    const selectedFilteredTopicsCount = selected.filter(({ id }) =>
      filteredTopicIds.has(id),
    ).length;

    helperText = `${helperText}. ${selectedFilteredTopicsCount} selected`;

    const hiddenSelectedTopicsCount =
      selected.length - selectedFilteredTopicsCount;
    if (hiddenSelectedTopicsCount > 0) {
      helperText = `${helperText} (${hiddenSelectedTopicsCount} hidden)`;
    }
  }

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setFilter(e.target.value);
  }

  const expandAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      function handleExpandAll() {
        treeActionsRef.current?.expandAll();
      },
  );

  const collapseAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      function handleCollapseAll() {
        treeActionsRef.current?.collapseAll();
      },
  );

  const selectAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      multiple &&
      function handleSelectAll() {
        const selectedTopicIds = new Set(selected.map(({ id }) => id));

        // Only add filtered topics that aren't already selected
        const additions = filteredTopics.filter(
          ({ id }) => !selectedTopicIds.has(id),
        );

        if (additions.length > 0) {
          onSelect([...selected, ...additions]);
        }
      },
  );

  const deselectAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      multiple &&
      function handleDeselectAll() {
        const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));

        const remaining = selected.filter(
          ({ id }) => !filteredTopicIds.has(id),
        );

        onSelect(remaining);
      },
  );

  return (
    <Stack spacing={1}>
      <Stack
        spacing={1}
        sx={{
          width: 1,
          position: "sticky",
          top: 0,
          zIndex: 1,
          backdropFilter: "blur(15px)",
          py: 1,
        }}
      >
        <TextField
          label="Filter topics"
          fullWidth
          disabled={disabled}
          value={filter}
          onChange={handleFilterChange}
          helperText={helperText}
        />
        <Stack spacing={1}>
          <ButtonGroup variant="outlined" size="small">
            <Button {...expandAllHandlerProps}>Expand All</Button>
            <Button {...collapseAllHandlerProps}>Collapse All</Button>
          </ButtonGroup>
          {multiple && (
            <ButtonGroup variant="outlined" size="small">
              <Button {...selectAllHandlerProps}>Select All</Button>
              <Button {...deselectAllHandlerProps}>Deselect All</Button>
            </ButtonGroup>
          )}
        </Stack>
      </Stack>
      <TreeContent
        key={deferredFilter}
        filteredTopics={filteredTopics}
        actionsRef={treeActionsRef}
        {...rest}
      />
    </Stack>
  );
}

type TreeContentProps = StrictOmit<TopicTreeProps, "topics"> & {
  filteredTopics: ReadonlyArray<Topic>;
  actionsRef: React.RefObject<TreeActions>;
};

const TreeContent = memo(function TreeContent({
  filteredTopics,
  actionsRef,
  multiple,
  selected,
  onSelect,
}: TreeContentProps) {
  const { rootNodes, nodeIdsToTopics } = useMemo(() => {
    return treeifyTopics(filteredTopics);
  }, [filteredTopics]);

  // When the tree first mounts or when the filter changes, all nodes in the
  // tree should be expanded. The parent component will set the filter as a
  // `key` on this component so this state will be reset when the filter changes.
  const [expanded, setExpanded] = useState(() => getInternalNodeIds(rootNodes));

  useImperativeHandle(
    actionsRef,
    () => ({
      expandAll() {
        setExpanded(getInternalNodeIds(rootNodes));
      },
      collapseAll() {
        setExpanded([]);
      },
    }),
    [rootNodes],
  );

  function handleExpansionChange(_: unknown, newExpanded: Array<NodeId>): void {
    if (newExpanded.length > expanded.length) {
      // A node was expanded
      setExpanded(newExpanded);
    } else {
      // A node was collapsed. Collapse all its expanded children too
      const newExpandedIds = new Set(newExpanded);

      // ID(s) of the node(s) which triggered this event. Should only be a
      // single node ID but being defensive here since this is sort of
      // circumventing the tree's default behavior. Would be better if MUI gave
      // the ID of the node which triggered the event instead of the final array
      // of currently-expanded node IDs
      const collapsedNodesIds = expanded.filter(
        (expandedNodeId) => !newExpandedIds.has(expandedNodeId),
      );
      invariant(
        collapsedNodesIds.length === 1,
        "Expected only a single node to have been collapsed",
      );
      const [collapsedNodeId] = collapsedNodesIds;

      setExpanded(
        expanded.filter(
          (expandedNodeId) => !expandedNodeId.startsWith(collapsedNodeId),
        ),
      );
    }
  }

  function handleNodeSelect(
    e: React.SyntheticEvent,
    nodeIds: NodeId | Array<NodeId>,
  ) {
    if (multiple) {
      if (e.type === "click") {
        // Default node multi-selection behavior on click is overridden.
        return;
      }

      const selectedTopicIds = new Set(selected.map(({ id }) => id));
      const newVisibleSelectedTopics = (nodeIds as Array<NodeId>).flatMap(
        (nodeId) => {
          const topic = nodeIdsToTopics.get(nodeId);

          if (topic === undefined) {
            // Not a leaf node
            return [];
          }

          return topic;
        },
      );

      const additions = newVisibleSelectedTopics.filter(
        ({ id }) => !selectedTopicIds.has(id),
      );

      const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));
      const newVisibleSelectedTopicIds = new Set(
        newVisibleSelectedTopics.map(({ id }) => id),
      );

      const remaining = selected.filter(({ id }) => {
        // Seems simpler to describe what to remove rather than what to keep.
        //
        // Hidden topics should always be kept so they aren't deselected when
        // the user isn't actively looking at them. For a filtered/visible
        // topic, if the new set of selected visible topics doesn't include its
        // ID then it must have been deselected and should be removed.
        const shouldRemove =
          filteredTopicIds.has(id) && !newVisibleSelectedTopicIds.has(id);

        // Make sure to negate this so the filter operation works as expected.
        return !shouldRemove;
      });

      onSelect([...remaining, ...additions]);
    } else {
      const topic = nodeIdsToTopics.get(nodeIds as NodeId);

      if (topic !== undefined) {
        onSelect(topic);
      }
    }
  }

  // MUI's multi-selection only lets users select more than one topic by
  // clicking if they're holding a modifier key like `ctrl` or `shift`. For
  // Studio's single use case where multi-select is used, it'd be better for
  // users to be able to toggle topics like checkboxes without needing to hold
  // a modifier key.
  function createNodeClickHandler(nodeId: NodeId) {
    return function handleNodeClick() {
      if (!multiple) {
        return;
      }

      const topic = nodeIdsToTopics.get(nodeId);

      if (topic === undefined) {
        // Not a leaf node
        return;
      }

      const selectedIndex = selected.findIndex(({ id }) => id === topic.id);

      if (selectedIndex === -1) {
        onSelect([...selected, topic]);
      } else {
        onSelect(selected.filter((_, index) => index !== selectedIndex));
      }
    };
  }

  if (rootNodes.length === 0) {
    return (
      <Typography>No topics or message types matched your search</Typography>
    );
  } else {
    let selectedNodeIds: Array<NodeId> | null;
    if (multiple) {
      const selectedTopicIds = new Set(selected.map(({ id }) => id));

      selectedNodeIds = [];
      for (const [nodeId, topic] of nodeIdsToTopics) {
        if (selectedTopicIds.has(topic.id)) {
          selectedNodeIds.push(nodeId);
        }
      }
    } else {
      selectedNodeIds = null;
    }

    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        multiSelect={multiple}
        expanded={expanded}
        onNodeToggle={handleExpansionChange}
        selected={selectedNodeIds}
        onNodeSelect={handleNodeSelect}
      >
        {rootNodes.map((rootNode) => (
          <TopicTreeItem
            key={rootNode.nodeId}
            node={rootNode}
            createNodeClickHandler={createNodeClickHandler}
          />
        ))}
      </TreeView>
    );
  }
});

/**
 * Generates a list of globally unique node IDs based on the parts of the
 * topic's name which are assumed to have already been split on the "/"
 * character.
 *
 * Each node ID is the absolute path from the base namespace to the given node.
 * Leaf nodes are distinguished from internal nodes by prepending a prefix.
 * For example, if run against a topic named "/namespace/sub_namespace/topic",
 * the node IDs would be:
 * ["internal:/namespace", "internal:/namespace/sub_namespace",
 * "leaf:/namespace/sub_namespace/topic"]
 *
 * @param pathParts Array of strings created by splitting a topic's name on "/"
 */
function generateTopicNodeIds(pathParts: ReadonlyArray<string>): Array<string> {
  const nodeIds = new Array<string>();

  pathParts.forEach((_, index, parts) => {
    // Some IDs can represent both actual topics (i.e. leaf nodes) and
    // internal nodes which have leaves under them. An example would be
    // the topics `/a/b` and `/a/b/c` where the node `b` would represent
    // both a leaf and an internal node. In this case there needs to be two
    // separate nodes in the tree differentiated by leaf status
    const prefix = index === parts.length - 1 ? "leaf" : "internal";
    const nodeId = `${prefix}:/${parts.slice(0, index + 1).join("/")}`;

    nodeIds.push(nodeId);
  });

  return nodeIds;
}

function treeifyTopics(topics: ReadonlyArray<Topic>) {
  const rootNodes = new Array<TopicNode>();
  const nodeIdsToTopics = new Map<NodeId, Topic>();

  // To easily look up existing nodes and append children to them, a cache
  // is kept mapping node IDs to that node's array of children. Root nodes have
  // no parent so `null` is used instead
  const cache = new Map<NodeId | null, Array<TopicNode>>([[null, rootNodes]]);

  topics.forEach((topic) => {
    const { name, typeName } = topic;

    const nameParts = compact(name.split("/"));
    const topicNodeIds = generateTopicNodeIds(nameParts);

    nameParts.forEach((pathPart, index, pathParts) => {
      const currentId = topicNodeIds[index];
      // Root nodes will be at index 0 and have no parent so the "path"
      // should default to `null`
      const parentPath = topicNodeIds[index - 1] ?? null;

      if (!cache.has(currentId)) {
        const node: TopicNode = {
          name: pathPart,
          nodeId: currentId,
          children: [],
        };

        if (index === pathParts.length - 1) {
          node.typeName = typeName;

          nodeIdsToTopics.set(currentId, topic);
        }

        cache.get(parentPath)!.push(node);
        cache.set(currentId, node.children);
      }
    });
  });

  return { rootNodes, nodeIdsToTopics };
}

function getInternalNodeIds(
  rootNodes: ReadonlyArray<TopicNode>,
): Array<NodeId> {
  const internalNodeIds = new Array<NodeId>();

  traverseTree(
    rootNodes,
    (node) => node.children,
    (node) => {
      if (node.nodeId.startsWith("internal:")) {
        internalNodeIds.push(node.nodeId);
      }
    },
  );

  return internalNodeIds;
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    // Make it line up vertically with dashed border on group
    marginLeft: "1px",
  },
  [`& .${treeItemClasses.group}`]: {
    borderLeft: "1px dashed",
    borderColor: alpha(theme.palette.divider, 0.35),
  },
  [`&:has(.Mui-focused) > .${treeItemClasses.group}`]: {
    borderColor: alpha(theme.palette.divider, 0.75),
  },
}));

function TopicTreeItem({
  node,
  createNodeClickHandler,
}: {
  node: TopicNode;
  createNodeClickHandler: (nodeId: NodeId) => () => void;
}) {
  const isLeaf = node.children.length === 0;

  function formatTopicName(name: TopicNode["name"]) {
    return (
      <>
        /
        <BreakableText separator={/(_)/} insertBreak="after">
          {name}
        </BreakableText>
      </>
    );
  }

  function formatTypeName(typeName: TopicNode["typeName"]) {
    return (
      <BreakableText separator={/([_/.])/} insertBreak="after">
        {typeName}
      </BreakableText>
    );
  }

  let label;
  if (isLeaf) {
    label = (
      <>
        <Typography component="span">{formatTopicName(node.name)}</Typography>{" "}
        <Typography component="span" variant="subtitle2" color="text.secondary">
          {formatTypeName(node.typeName)}
        </Typography>
      </>
    );
  } else {
    label = (
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        {formatTopicName(node.name)}
      </Typography>
    );
  }

  return (
    <StyledTreeItem
      nodeId={node.nodeId}
      label={label}
      onClick={createNodeClickHandler(node.nodeId)}
    >
      {isLeaf
        ? null
        : node.children.map((childNode) => (
            <TopicTreeItem
              key={childNode.nodeId}
              node={childNode}
              createNodeClickHandler={createNodeClickHandler}
            />
          ))}
    </StyledTreeItem>
  );
}
