import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Pause,
  PlayArrow,
  Replay,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { PlaySpeed, SquareWave } from "mdi-material-ui";
import { QueryRenderer } from "~/components/QueryRenderer";
import { LEFTWARDS_ARROW, RIGHTWARDS_ARROW } from "~/constants";
import {
  millisecondsToNanoseconds,
  relativeToUtcNanoseconds,
  secondsToNanoseconds,
  utcToRelativeNanoseconds,
} from "~/lib/dates";
import { useHotkeys } from "~/lib/hotkeys";
import { invariant } from "~/lib/invariant";
import { pluralize } from "~/utils";
import { usePlayerActions } from "../actions";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSettings,
  usePlaybackSource,
  usePlaybackTimer,
  usePlaybackTimerPause,
} from "../playback";
import type { PlaybackTag } from "../tags";
import { TagMarker, usePlaybackTags, useShouldShowTags } from "../tags";
import type { TimestepValue } from "../types";
import { PlaybackSpeed, Timestep } from "../types";

const classNames = {
  sliderContainer: "slider-container",
  tagsLoading: "tags-loading",
  playbackTimestamp: "playback-timestamp",
  controlsSection: "controls-section",
  leftControls: "left-controls",
  centerControls: "center-controls",
  rightControls: "right-controls",
} as const;

const Root = styled("div")(({ theme }) => ({
  flex: "none",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  "& .MuiSkeleton-root": {
    display: "inline-block",
  },
  [`& .${classNames.sliderContainer}`]: {
    marginInline: theme.spacing(2),
    position: "relative",
  },
  ["& .MuiSlider-root"]: {
    "& :is(.MuiSlider-track, .MuiSlider-thumb)": {
      transition: "none",
    },
    "& .MuiSlider-thumb": {
      // Custom marks are positioned over the rest of the slider,
      // specifically the padding area so it doesn't steal click events
      // meant for the mark. However, the thumb still needs to appear
      // over the custom marks.
      // Since custom marks also use a z-index on hover to appear over
      // neighboring marks, the thumb's z-index must ensure it still
      // appears over hovered custom markers.
      zIndex: 2,
    },
  },
  [`& .${classNames.tagsLoading}`]: {
    position: "absolute",
    top: 0,
    translate: "0 -75%",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  [`& .${classNames.playbackTimestamp}`]: {
    ...theme.typography.body2,
    margin: 0,
    display: "inline",
    fontFamily: "monospace",
  },
  [`& .${classNames.controlsSection}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridTemplateAreas: '"left center right"',
  },
  [`& .${classNames.leftControls}`]: {
    gridArea: "left",
    display: "flex",
    alignItems: "center",
  },
  [`& .${classNames.centerControls}`]: {
    gridArea: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classNames.rightControls}`]: {
    gridArea: "right",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
}));

function offsetNsForTimestep(timestep: TimestepValue): number {
  return Number(
    timestep === Timestep.Second
      ? secondsToNanoseconds(1)
      : millisecondsToNanoseconds(100),
  );
}

export function PlaybackController({
  layoutProfilesMenu,
  shareableLinkButton,
}: {
  // TODO: Should probably make these more generic, like `actions` or something
  //       like that.
  layoutProfilesMenu?: React.ReactNode;
  shareableLinkButton?: React.ReactNode;
}) {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const speedMenuState = usePopupState({
    variant: "popover",
    popupId: "speed-menu",
  });
  const stepMenuStep = usePopupState({
    variant: "popover",
    popupId: "step-menu",
  });

  const offsetNs = offsetNsForTimestep(playbackSettings.timestep);

  const playbackTimer = usePlaybackTimer();

  const [isSeeking, setIsSeeking] = useState(false);
  usePlaybackTimerPause(isSeeking);

  const playerActions = usePlayerActions();
  const { tick: dispatchTick } = playerActions;

  useEffect(
    function managePlaybackTimer() {
      if (!playbackSource.isPlaying) {
        return;
      }

      playbackTimer.set({
        onTick: dispatchTick,
        speed: playbackSettings.speed,
        timestep: playbackSettings.timestep,
      });

      return () => {
        playbackTimer.clear();
      };
    },
    [
      playbackSource.isPlaying,
      playbackTimer,
      dispatchTick,
      playbackSettings.speed,
      playbackSettings.timestep,
    ],
  );

  const { spaceRef, leftArrowRef, rightArrowRef } = usePlaybackHotkeys();

  const shouldShowTags = useShouldShowTags();
  const playbackTagsQuery = usePlaybackTags();

  function handleSliderChange(
    _: unknown,
    value: number | Array<number>,
    thumbIndex: number,
  ): void {
    invariant(!playbackSource.isLoading, "Slider value shouldn't be changing");

    setIsSeeking(true);

    if (playbackSource.inRangeMode) {
      invariant(
        Array.isArray(value) && value.length === 2,
        "Expected a 2-tuple in range-select mode",
      );

      playerActions.setRange({
        startTime: relativeToUtcNanoseconds(
          value[0],
          playbackSource.bounds.startTime,
        ),
        endTime: relativeToUtcNanoseconds(
          value[1],
          playbackSource.bounds.startTime,
        ),
      });
      playerActions.seek(
        relativeToUtcNanoseconds(
          value[thumbIndex],
          playbackSource.bounds.startTime,
        ),
      );
    } else {
      invariant(
        typeof value === "number",
        "Expected a number in playback mode",
      );

      playerActions.seek(
        relativeToUtcNanoseconds(value, playbackSource.bounds.startTime),
      );
    }
  }

  const isAtStart =
    playbackSource.timestamp === playbackSource.bounds?.startTime;
  const isAtEnd = playbackSource.timestamp === playbackSource.bounds?.endTime;

  let playbackControl;
  if (playbackSource.isPlaying) {
    playbackControl = (
      <Tooltip title="Pause (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.inRangeMode}
            aria-label="Pause log playback"
            onClick={playerActions.pause}
            size="medium"
          >
            <Pause />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else if (!playbackSource.isLoading && isAtEnd) {
    playbackControl = (
      <Tooltip title="Replay (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.inRangeMode}
            aria-label="Replay log from beginning"
            onClick={playerActions.restart}
            size="medium"
          >
            <Replay />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else {
    playbackControl = (
      <Tooltip title="Play (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.isLoading || playbackSource.inRangeMode}
            aria-label="Start or resume log playback"
            onClick={playerActions.play}
            size="medium"
          >
            <PlayArrow />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Root>
      <div className={classNames.sliderContainer}>
        <Slider
          disabled={playbackSource.isLoading}
          valueLabelDisplay={playbackSource.inRangeMode ? "on" : "auto"}
          valueLabelFormat={formatPlaybackTimestamp}
          min={0}
          max={
            playbackSource.isLoading
              ? 0
              : utcToRelativeNanoseconds(
                  playbackSource.bounds.endTime,
                  playbackSource.bounds.startTime,
                )
          }
          step={offsetNs}
          onChange={handleSliderChange}
          onChangeCommitted={() => {
            setIsSeeking(false);
          }}
          value={
            playbackSource.inRangeMode
              ? [
                  utcToRelativeNanoseconds(
                    playbackSource.range.startTime,
                    playbackSource.bounds.startTime,
                  ),
                  utcToRelativeNanoseconds(
                    playbackSource.range.endTime,
                    playbackSource.bounds.startTime,
                  ),
                ]
              : utcToRelativeNanoseconds(
                  playbackSource.timestamp ?? 0n,
                  playbackSource.bounds?.startTime ?? 0n,
                )
          }
        />
        {playbackTagsQuery.isFetching && (
          <div className={classNames.tagsLoading}>
            <CircularProgress size="1rem" />
            <Typography>Loading tags...</Typography>
          </div>
        )}
        {shouldShowTags &&
          playbackTagsQuery.isSuccess &&
          playbackTagsQuery.data.map((tag) => (
            <TagMarker key={tag.id} timestamp={tag.timestamp} />
          ))}
      </div>
      <div className={classNames.controlsSection}>
        <div className={classNames.leftControls}>
          <Tooltip title={`Previous (${LEFTWARDS_ARROW})`}>
            <span>
              <IconButton
                ref={leftArrowRef}
                disabled={
                  isAtStart ||
                  playbackSource.isLoading ||
                  playbackSource.inRangeMode
                }
                aria-label="Move backward to previous timestamp"
                onClick={playerActions.previousFrame}
                size="medium"
              >
                <SkipPrevious />
              </IconButton>
            </span>
          </Tooltip>
          {playbackControl}
          <Tooltip title={`Next (${RIGHTWARDS_ARROW})`}>
            <span>
              <IconButton
                ref={rightArrowRef}
                disabled={
                  isAtEnd ||
                  playbackSource.isLoading ||
                  playbackSource.inRangeMode
                }
                aria-label="Move forward to next timestamp"
                onClick={playerActions.nextFrame}
                size="medium"
              >
                <SkipNext />
              </IconButton>
            </span>
          </Tooltip>
          <div>
            <pre className={classNames.playbackTimestamp}>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.timestamp)}
            </pre>
            {" / "}
            <pre className={classNames.playbackTimestamp}>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.bounds.endTime)}
            </pre>
          </div>
        </div>
        {!playbackSource.isLoading && shouldShowTags && (
          <QueryRenderer
            query={playbackTagsQuery}
            success={(tags) => {
              // Timestamps are assumed to be sorted in ascending order
              const previousTag = tags.findLast(
                (tag) => tag.timestamp < playbackSource.timestamp,
              );
              const nextTag = tags.find(
                (tag) => tag.timestamp > playbackSource.timestamp,
              );

              function makeTagSeekHandler(tag: PlaybackTag | undefined) {
                return function handleTagSeek() {
                  if (tag === undefined) {
                    return;
                  }

                  playerActions.seek(tag.timestamp);
                };
              }

              return (
                <div className={classNames.centerControls}>
                  <Tooltip title="Previous tag">
                    <span>
                      <IconButton
                        size="medium"
                        disabled={previousTag === undefined}
                        onClick={makeTagSeekHandler(previousTag)}
                      >
                        <KeyboardDoubleArrowLeft />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography color="text.secondary">
                    {pluralize(tags.length, "tag")}
                  </Typography>
                  <Tooltip title="Next tag">
                    <span>
                      <IconButton
                        size="medium"
                        disabled={nextTag === undefined}
                        onClick={makeTagSeekHandler(nextTag)}
                      >
                        <KeyboardDoubleArrowRight />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              );
            }}
          />
        )}
        <div className={classNames.rightControls}>
          {layoutProfilesMenu}
          {shareableLinkButton}
          <Tooltip title="Playback speed">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open playback speed menu"
                size="medium"
                {...bindTrigger(speedMenuState)}
              >
                <PlaySpeed />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(speedMenuState)}>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesOne}
              onClick={() =>
                playerActions.setPlaybackSpeed(PlaybackSpeed.TimesOne)
              }
            >
              1x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesTwo}
              onClick={() =>
                playerActions.setPlaybackSpeed(PlaybackSpeed.TimesTwo)
              }
            >
              2x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesFive}
              onClick={() =>
                playerActions.setPlaybackSpeed(PlaybackSpeed.TimesFive)
              }
            >
              5x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesTen}
              onClick={() =>
                playerActions.setPlaybackSpeed(PlaybackSpeed.TimesTen)
              }
            >
              10x
            </MenuItem>
          </Menu>
          <Tooltip title="Timestep">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open timestep control menu"
                size="medium"
                {...bindTrigger(stepMenuStep)}
              >
                <SquareWave />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(stepMenuStep)}>
            <MenuItem
              selected={playbackSettings.timestep === Timestep.Second}
              onClick={() => playerActions.setPlaybackTimestep(Timestep.Second)}
            >
              1 second
            </MenuItem>
            <MenuItem
              selected={playbackSettings.timestep === Timestep.Decisecond}
              onClick={() =>
                playerActions.setPlaybackTimestep(Timestep.Decisecond)
              }
            >
              0.1 seconds
            </MenuItem>
          </Menu>
        </div>
      </div>
    </Root>
  );
}

function usePlaybackHotkeys() {
  const spaceRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "Space",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      spaceRef.current?.click();
    }, []),
  );

  const leftArrowRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "ArrowLeft",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      leftArrowRef.current?.click();
    }, []),
  );

  const rightArrowRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "ArrowRight",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      rightArrowRef.current?.click();
    }, []),
  );

  // Rather than directly dispatching actions which would require knowing the
  // current player state and whether those actions should be disabled, a ref
  // can be attached to the button whose action should be triggered when the
  // hotkey is pressed. The hotkey handler just needs to programmatically click
  // the button which has the added benefit of doing nothing if the button
  // is disabled.
  return { spaceRef, leftArrowRef, rightArrowRef };
}
