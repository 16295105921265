import type { Draft } from "immer";
import { produce } from "immer";
import type { ImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import { clamp, get, pull } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import type { PointerLocation } from "../hooks";
import type { PlayerRecord } from "../record-store";
import type {
  ContainerNode,
  InitializedPanelNode,
  LayoutNode,
  PanelNode,
  UninitializedPanelNode,
} from "./api";
import type { SplitOrientation, VisualizationType } from "./constants";
import {
  ClipInsetSide,
  FlipDirection,
  IMAGE_ROTATION_STEP_MAGNITUDE_DEG,
  MAX_CHART_FIELDS,
  RotationDirection,
} from "./constants";
import { getDefaultVisualization } from "./topic-config";
import { calculateRotationQuadrant, walkLayoutTree } from "./utils";

type LayoutState = { root: LayoutNode; nextId: number };

/**
 * Split a panel, creating a new, uninitialized sibling panel. The orientation
 * describes if the sibling should be to the right of or below the base panel.
 */
export type SplitPanelAction = {
  type: "split-panel";
  payload: {
    panelId: PanelNode["id"];
    orientation: SplitOrientation;
  };
};

export function splitPanel(
  payload: SplitPanelAction["payload"],
): SplitPanelAction {
  return {
    type: "split-panel",
    payload,
  };
}

/**
 * Remove a panel from the tree entirely. If the panel is the only one in the
 * tree, a new, uninitialized panel replaces it.
 */
export type RemovePanelAction = {
  type: "remove-panel";
  payload: {
    panelId: PanelNode["id"];
  };
};

export function removePanel(
  payload: RemovePanelAction["payload"],
): RemovePanelAction {
  return {
    type: "remove-panel",
    payload,
  };
}

/**
 * Resize a panel or container. The flex describes its relative size compared
 * to its sibling.
 */
export type ResizeNodeAction = {
  type: "resize-node";
  payload: {
    nodeId: LayoutNode["id"];
    flex: number;
  };
};

export function resizeNode(
  payload: ResizeNodeAction["payload"],
): ResizeNodeAction {
  return {
    type: "resize-node",
    payload,
  };
}

/**
 * Load a layout, overwriting the existing layout completely. Useful to load
 * layout profiles kept in a user's web storage.
 */
export type LoadLayoutAction = {
  type: "load-layout";
  payload: {
    layout: LayoutNode;
  };
};

export function loadLayout(
  payload: LoadLayoutAction["payload"],
): LoadLayoutAction {
  return {
    type: "load-layout",
    payload,
  };
}

/**
 * Initialize the panel using the given topic. The panel's visualization-related
 * fields will be given default values. The initial visualization depends on the
 * topic's message type, if any:
 *  - If the message type supports image data, the panel will be initialized
 *    to the "image" visualization
 *  - If the message type supports GPS data, the panel will be initialized to
 *    the "map" visualization
 *  - For all other message types, the panel will be initialized to the
 *    "timeline" visualization
 *
 * Invariants:
 *  - Panel must be uninitialized
 */
export type SelectTopicAction = {
  type: "select-topic";
  payload: {
    panelId: PanelNode["id"];
    topic: Topic;
  };
};

export function selectTopic(
  payload: SelectTopicAction["payload"],
): SelectTopicAction {
  return {
    type: "select-topic",
    payload,
  };
}

/**
 * Toggle image colorization
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type ToggleImageColorizationAction = {
  type: "toggle-image-colorization";
  payload: {
    panelId: PanelNode["id"];
    colorize: boolean;
  };
};

export function toggleImageColorization(
  payload: ToggleImageColorizationAction["payload"],
): ToggleImageColorizationAction {
  return {
    type: "toggle-image-colorization",
    payload,
  };
}

/**
 * Rotate the displayed image 90 degrees in the specified direction
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type RotateImageAction = {
  type: "rotate-image";
  payload: {
    panelId: PanelNode["id"];
    direction: RotationDirection;
  };
};

export function rotateImage(
  payload: RotateImageAction["payload"],
): RotateImageAction {
  return {
    type: "rotate-image",
    payload,
  };
}

/**
 * Set or clear the direction in which the image should be flipped.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SetImageFlipDirectionAction = {
  type: "set-image-flip-direction";
  payload: {
    panelId: PanelNode["id"];
    flipDirection: FlipDirection | null;
  };
};

export function setImageFlipDirection(
  payload: SetImageFlipDirectionAction["payload"],
): SetImageFlipDirectionAction {
  return {
    type: "set-image-flip-direction",
    payload,
  };
}

/**
 * Set or clear the inference topic to be visualized in this panel alongside
 * the main topic.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SetInferenceTopicAction = {
  type: "set-inference-topic";
  payload: {
    panelId: PanelNode["id"];
    inferenceTopic: Topic | null;
  };
};

export function setInferenceTopic(
  payload: SetInferenceTopicAction["payload"],
): SetInferenceTopicAction {
  return {
    type: "set-inference-topic",
    payload,
  };
}

export type ToggleInferenceTransformLockAction = {
  type: "toggle-inference-transform-lock";
  payload: {
    panelId: PanelNode["id"];
    lock: boolean;
  };
};

export function toggleInferenceTransformLock(
  payload: ToggleInferenceTransformLockAction["payload"],
): ToggleInferenceTransformLockAction {
  return {
    type: "toggle-inference-transform-lock",
    payload,
  };
}

/**
 * Adds a field to be plotted in the chart visualization. The field can be
 * top-level or nested and must be available in the `data` payload
 * argument. The field must follow the syntax for lodash's `get` method. The
 * value at the given field should be a numeric type.
 *
 * There are some circumstances under which the field will not be added, though
 * these circumstances do not represent an error:
 *  - Maximum number of fields already added
 *  - Field has already been added
 *  - Field value is not a numeric type
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type AddChartFieldAction = {
  type: "add-chart-field";
  payload: {
    panelId: PanelNode["id"];
    field: string;
    data: PlayerRecord<"default">["data"];
  };
};

export function addChartField(
  payload: AddChartFieldAction["payload"],
): AddChartFieldAction {
  return {
    type: "add-chart-field",
    payload,
  };
}

/**
 * Removes the given field from the panel's chart visualization fields.
 *
 * Invariants:
 *  - Panel must be initialized
 *  - Field must have previously been added
 */
export type RemoveChartFieldAction = {
  type: "remove-chart-field";
  payload: {
    panelId: PanelNode["id"];
    field: string;
  };
};

export function removeChartField(
  payload: RemoveChartFieldAction["payload"],
): RemoveChartFieldAction {
  return {
    type: "remove-chart-field",
    payload,
  };
}

/**
 * Selects the provided tag for the panel, unless that tag is already selected,
 * in which case the tag will be un-selected.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SelectTagAction = {
  type: "select-tag";
  payload: {
    panelId: PanelNode["id"];
    tag: string;
  };
};

export function selectTag(
  payload: SelectTagAction["payload"],
): SelectTagAction {
  return {
    type: "select-tag",
    payload,
  };
}

/**
 * Change whether a detection-type inference output's bounding boxes should be
 * drawn.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ShowDetectionBoundingBoxesAction = {
  type: "show-detection-bounding-boxes";
  payload: {
    panelId: PanelNode["id"];
    showDetectionBoundingBoxes: boolean;
  };
};

export function showDetectionBoundingBoxes(
  payload: ShowDetectionBoundingBoxesAction["payload"],
): ShowDetectionBoundingBoxesAction {
  return {
    type: "show-detection-bounding-boxes",
    payload,
  };
}

/**
 * Change whether a detection-type inference output's detection class names
 * should be displayed.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ShowDetectionClassNamesAction = {
  type: "show-detection-class-names";
  payload: {
    panelId: PanelNode["id"];
    showDetectionClassNames: boolean;
  };
};

export function showDetectionClassNames(
  payload: ShowDetectionClassNamesAction["payload"],
): ShowDetectionClassNamesAction {
  return {
    type: "show-detection-class-names",
    payload,
  };
}

/**
 * Change the visibility for this class of detected objects.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ChangeObjectClassVisibilityAction = {
  type: "change-object-class-visibility";
  payload: {
    panelId: PanelNode["id"];
    className: string;
    hideClass: boolean;
  };
};

export function changeObjectClassVisibility(
  payload: ChangeObjectClassVisibilityAction["payload"],
): ChangeObjectClassVisibilityAction {
  return {
    type: "change-object-class-visibility",
    payload,
  };
}

/**
 * Set the opacity for the segmentation and depth-estimation inference result
 * image overlay.
 *
 * Invariants:
 *   - Panel must be initialized
 *   - Opacity must be in the range [0, 1]
 */
export type SetInferenceImageOpacityAction = {
  type: "set-inference-image-opacity";
  payload: {
    panelId: PanelNode["id"];
    opacity: number;
  };
};

export function setInferenceImageOpacity(
  payload: SetInferenceImageOpacityAction["payload"],
): SetInferenceImageOpacityAction {
  return {
    type: "set-inference-image-opacity",
    payload,
  };
}

/**
 * Toggle inference image colorization.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ToggleInferenceImageColorizationAction = {
  type: "toggle-inference-image-colorization";
  payload: {
    panelId: PanelNode["id"];
    colorize: boolean;
  };
};

export function toggleInferenceImageColorization(
  payload: ToggleInferenceImageColorizationAction["payload"],
): ToggleInferenceImageColorizationAction {
  return {
    type: "toggle-inference-image-colorization",
    payload,
  };
}

/**
 * Set the new inference image clip inset taking into consideration
 * transformations.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SetInferenceImageClipInsetAction = {
  type: "set-inference-image-clip-inset";
  payload: {
    panelId: PanelNode["id"];
    pointerLocation: PointerLocation;
    baseImageDimensions: { naturalWidth: number; naturalHeight: number };
  };
};

export function setInferenceImageClipInset(
  payload: SetInferenceImageClipInsetAction["payload"],
): SetInferenceImageClipInsetAction {
  return {
    type: "set-inference-image-clip-inset",
    payload,
  };
}

/**
 * Set the size of individual points in the 3D point cloud.
 *
 * Invariants:
 *   - Panel must be initialized
 *   - Point size must be in the range [0, 0.1]
 */
export type SetPointCloudPointSizeAction = {
  type: "set-point-cloud-point-size";
  payload: {
    panelId: PanelNode["id"];
    pointSize: number;
  };
};

export function setPointCloudPointSize(
  payload: SetPointCloudPointSizeAction["payload"],
): SetPointCloudPointSizeAction {
  return {
    type: "set-point-cloud-point-size",
    payload,
  };
}

/**
 * Switches the panel to the given visualization. To choose the "chart"
 * visualization, at least one field must have already been selected.
 *
 * Invariants:
 *  - Panel must be initialized
 *  - If `visualization === "chart"`, must be > 0 fields added
 */
export type ChooseVisualizationAction = {
  type: "choose-visualization";
  payload: {
    panelId: PanelNode["id"];
    tab: VisualizationType;
  };
};

export function chooseVisualization(
  payload: ChooseVisualizationAction["payload"],
): ChooseVisualizationAction {
  return {
    type: "choose-visualization",
    payload,
  };
}

/**
 * Clears the selected topic for the panel, returning the panel to an
 * uninitialized state.
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type ChooseNewTopicAction = {
  type: "choose-new-topic";
  payload: {
    panelId: PanelNode["id"];
  };
};

export function chooseNewTopic(
  payload: ChooseNewTopicAction["payload"],
): ChooseNewTopicAction {
  return {
    type: "choose-new-topic",
    payload,
  };
}

export type PanelLayoutAction =
  | SplitPanelAction
  | RemovePanelAction
  | ResizeNodeAction
  | SelectTopicAction
  | ToggleImageColorizationAction
  | RotateImageAction
  | SetImageFlipDirectionAction
  | SetInferenceTopicAction
  | ToggleInferenceTransformLockAction
  | AddChartFieldAction
  | RemoveChartFieldAction
  | SelectTagAction
  | ShowDetectionBoundingBoxesAction
  | ShowDetectionClassNamesAction
  | ChangeObjectClassVisibilityAction
  | SetInferenceImageOpacityAction
  | ToggleInferenceImageColorizationAction
  | SetInferenceImageClipInsetAction
  | SetPointCloudPointSizeAction
  | ChooseVisualizationAction
  | ChooseNewTopicAction
  | LoadLayoutAction;

export const emptyLayout: LayoutNode = makeUninitializedPanel(0, null, 1);

export function createReducer(
  initialLayout: LayoutNode,
): ImmerReducer<{ state: LayoutState | null }, PanelLayoutAction> {
  return function reducer(draft, action) {
    // Storing the real state as a property makes it easier to overwrite an
    // initial `null` state value. The `produce` call is needed so
    // `initialLayout` is drafted and the reducer doesn't accidentally
    // mutate the original object.
    draft.state = produce(
      draft.state ?? getStateFromLayout(initialLayout),
      (draftState) => {
        switch (action.type) {
          case "split-panel": {
            const panel = getPanel(draftState, action.payload.panelId);

            const panelParentId = panel.parentId;

            const siblingId = draftState.nextId++;
            const newParentId = draftState.nextId++;

            const sibling = makeUninitializedPanel(siblingId, newParentId, 0.5);

            const newParent: ContainerNode = {
              type: "container",
              parentId: panelParentId,
              id: newParentId,
              flex: panel.flex,
              orientation: action.payload.orientation,
              firstChild: panel,
              secondChild: sibling,
            };

            panel.parentId = newParentId;
            panel.flex = 0.5;

            replaceNode(draftState, panelParentId, panel, newParent);

            return;
          }
          case "remove-panel": {
            const panel = getPanel(draftState, action.payload.panelId);

            const panelParentId = panel.parentId;

            if (panelParentId === null) {
              draftState.root = makeUninitializedPanel(
                draftState.nextId++,
                null,
                1,
              );
            } else {
              const parent = getContainer(draftState, panelParentId);

              const sibling =
                panel === parent.firstChild
                  ? parent.secondChild
                  : parent.firstChild;

              replaceNode(draftState, parent.parentId, parent, sibling);

              sibling.parentId = parent.parentId;

              if (parent.parentId === null) {
                sibling.flex = 1;
              } else {
                sibling.flex = parent.flex;
              }
            }

            return;
          }
          case "resize-node": {
            const node = getNode(draftState, action.payload.nodeId);

            node.flex = action.payload.flex;

            return;
          }
          case "load-layout": {
            return getStateFromLayout(action.payload.layout);
          }
          case "select-topic": {
            const panel = getUninitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const initializedPanel = makeInitializedPanel(
              panel.id,
              panel.parentId,
              panel.flex,
              action.payload.topic,
            );

            replaceNode(
              draftState,
              initializedPanel.parentId,
              panel,
              initializedPanel,
            );

            return;
          }
          case "toggle-image-colorization": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.colorizeImage = action.payload.colorize;

            return;
          }
          case "rotate-image": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.imageRotationDeg = calculateNewRotationDeg(
              panel.imageRotationDeg,
              action.payload.direction,
            );

            if (panel.lockInferenceTransform) {
              panel.inferenceRotationDeg = calculateNewRotationDeg(
                panel.inferenceRotationDeg,
                action.payload.direction,
              );
            }

            return;
          }
          case "set-image-flip-direction": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const {
              payload: { flipDirection },
            } = action;

            if (panel.imageFlipDirection === flipDirection) {
              // Probably shouldn't happen but it's a no-op
              return;
            }

            if (panel.lockInferenceTransform) {
              // Locked output overlay's initial flip direction might not correspond
              // to the image's. For example, the image may be horizontally reflected
              // and the user needed to flip it horizontally and _then_ lock the
              // overlay. In such a situation, the two can never have the same
              // flip direction *but* they'll only be a reflection of each other along
              // a single axis (since reflection along both axes isn't permitted by
              // Studio as it's just a 180-degree rotation).
              //
              // The overlay's stored flip direction and rotation should always
              // represent the transformations necessary to keep the it aligned
              // relative to the image in the same manner as when the user locked
              // the overlay (or when the panel was initialized as locked is the
              // default setting).

              const currentFlipDirections = new Set([
                panel.imageFlipDirection,
                panel.inferenceFlipDirection,
              ]);

              const containsOrthogonalFlips =
                currentFlipDirections.has(FlipDirection.X) &&
                currentFlipDirections.has(FlipDirection.Y);

              invariant(
                !containsOrthogonalFlips,
                "Images and segmentations should not be flipped orthogonally",
              );

              if (currentFlipDirections.size === 1) {
                // Both image and segmentations share the same flip direction, so
                // they should remain that way
                panel.inferenceFlipDirection = flipDirection;
              } else if (currentFlipDirections.has(flipDirection)) {
                // By this point, the segmentations are guaranteed to be reflected
                // relative to the other along some axis *and* the payload's flip
                // direction matches the segmentations'. In this case, the
                // segmentations' and image's flip directions need to be swapped.
                panel.inferenceFlipDirection = panel.imageFlipDirection;
              } else {
                // By this point, the payload flip direction is guaranteed to be the
                // x or y axis but doesn't correspond to either the image's or the
                // segmentations' flip directions. Since the segmentations are
                // reflected relative to the image, applying this second flip along
                // the orthogonal axis is equivalent to rotating 180 degrees.
                panel.inferenceFlipDirection = null;
                panel.inferenceRotationDeg +=
                  2 * IMAGE_ROTATION_STEP_MAGNITUDE_DEG;
              }
            }

            // Image's final flip direction is always the payload's flip direction
            panel.imageFlipDirection = flipDirection;

            return;
          }
          case "set-inference-topic": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.inferenceTopicName =
              action.payload.inferenceTopic?.name ?? null;

            return;
          }
          case "toggle-inference-transform-lock": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.lockInferenceTransform = action.payload.lock;

            if (!action.payload.lock) {
              panel.inferenceRotationDeg = 0;
              panel.inferenceFlipDirection = null;
            }

            return;
          }
          case "add-chart-field": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const { field, data } = action.payload;

            if (panel.fields.length >= MAX_CHART_FIELDS) {
              return;
            }

            if (panel.fields.includes(field)) {
              return;
            }

            if (typeof get(data, field) !== "number") {
              return;
            }

            panel.fields.push(field);

            return;
          }
          case "remove-chart-field": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const { field } = action.payload;

            invariant(
              panel.fields.includes(field),
              `Field not currently selected: "${field}"`,
            );

            pull(panel.fields, field);

            return;
          }
          case "select-tag": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            if (panel.selectedTag === action.payload.tag) {
              panel.selectedTag = null;
            } else {
              panel.selectedTag = action.payload.tag;
            }

            return;
          }
          case "show-detection-bounding-boxes": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.showDetectionBoundingBoxes =
              action.payload.showDetectionBoundingBoxes;

            return;
          }
          case "show-detection-class-names": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.showDetectionClassNames =
              action.payload.showDetectionClassNames;

            return;
          }
          case "change-object-class-visibility": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const {
              payload: { className, hideClass },
            } = action;

            const isCurrentlyHidden =
              panel.hiddenObjectClassNames.includes(className);

            if (isCurrentlyHidden && !hideClass) {
              pull(panel.hiddenObjectClassNames, className);
            } else if (!isCurrentlyHidden && hideClass) {
              panel.hiddenObjectClassNames.push(className);
            }

            return;
          }
          case "set-inference-image-opacity": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const {
              payload: { opacity },
            } = action;

            invariant(
              0 <= opacity && opacity <= 1,
              "Opacity must be in range [0, 1]",
            );

            panel.inferenceImageOpacity = opacity;

            return;
          }
          case "toggle-inference-image-colorization": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            panel.colorizeInferenceImage = action.payload.colorize;

            return;
          }
          case "set-inference-image-clip-inset": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const {
              inferenceRotationDeg,
              inferenceFlipDirection,
              inferenceImageClipInset: { side: insetSide },
            } = panel;

            // The inset needs to be calculated differently depending on the
            // inset side:
            // 1. For left and right sides, use the x coordinate and width
            // 2. For top and bottom sides, use the y coordinate and height
            // 3. For left and top sides, don't need to invert the percentage
            // 4. For right and bottom sides, need to invert the percentage
            //
            // Inversion is necessary for the right and bottom sides because
            // the pointer coordinates are always relative to the top left
            // corner of the container, ignoring transforms.
            //
            // Once transforms are thrown into the mix, it's no longer as simple
            // as using a lookup table. Rather, the percentage needs to be
            // calculated from where the base side *appears* to be after
            // transforms are applied. If, for example, the inset is from the
            // left side but transforms make the image's left side appear as
            // the bottom side of the container, then the new inset percentage
            // needs to be calculated using the parameters for the bottom side.
            //
            // This array's order is important for the index calculations that
            // happen below. While the starting element is arbitrary, it's
            // crucial each successive element is the next apparent side if you
            // were to rotate the image once (in the positive direction). So, if
            // we have an inset from the left side and rotate the image twice,
            // the inset would appear to be the right side on screen. Add 2 (for
            // the number of rotations) to the index of the left side's params
            // and you end up at the params for the right side.
            const sideCalculationParams = [
              { axis: "x", invert: false } /* left */,
              { axis: "y", invert: false } /* top */,
              { axis: "x", invert: true } /* right */,
              { axis: "y", invert: true } /* bottom */,
            ];

            // Get the index into the params lookup table according to the base
            // inset side. This MUST have the same order as the elements in the
            // array above.
            let sideIndex = [
              ClipInsetSide.Left,
              ClipInsetSide.Top,
              ClipInsetSide.Right,
              ClipInsetSide.Bottom,
            ].indexOf(insetSide);

            if (
              sideCalculationParams[sideIndex].axis === inferenceFlipDirection
            ) {
              // A flip along the same axis as the mouse coordinate that'll
              // be used is visually equivalent to 2 rotations
              sideIndex += 2;
            }

            sideIndex += calculateRotationQuadrant(inferenceRotationDeg);

            const apparentSideCalculationParams =
              sideCalculationParams[sideIndex % sideCalculationParams.length];

            const {
              payload: { pointerLocation },
            } = action;

            // TODO: Calculate relative to scaled image dimensions, not container
            let insetPercent: number;

            if (apparentSideCalculationParams.axis === "x") {
              insetPercent = pointerLocation.x / pointerLocation.width;
            } else {
              insetPercent = pointerLocation.y / pointerLocation.height;
            }

            if (apparentSideCalculationParams.invert) {
              insetPercent = 1 - insetPercent;
            }

            panel.inferenceImageClipInset.percent = clamp(insetPercent, 0, 1);

            return;
          }
          case "set-point-cloud-point-size": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const {
              payload: { pointSize },
            } = action;

            invariant(
              0 <= pointSize && pointSize <= 0.1,
              "Point size must be in range [0, 0.1]",
            );

            panel.pointCloudPointSize = pointSize;

            return;
          }
          case "choose-visualization": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            invariant(
              action.payload.tab !== "chart" || panel.fields.length > 0,
              "Must have at least 1 field selected to show chart",
            );

            panel.visualization = action.payload.tab;

            return;
          }
          case "choose-new-topic": {
            const panel = getInitializedPanel(
              draftState,
              action.payload.panelId,
            );

            const uninitializedPanel = makeUninitializedPanel(
              panel.id,
              panel.parentId,
              panel.flex,
            );

            replaceNode(
              draftState,
              uninitializedPanel.parentId,
              panel,
              uninitializedPanel,
            );

            return;
          }
          default: {
            assertNever(action);
          }
        }
      },
    );
  };
}

// Helpers

function getStateFromLayout(layout: LayoutNode): LayoutState {
  let maxId = layout.id;
  walkLayoutTree(layout, (node) => {
    maxId = Math.max(maxId, node.id);
  });

  return {
    root: layout,
    nextId: maxId + 1,
  };
}

function makeUninitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
): UninitializedPanelNode {
  return {
    type: "panel",
    parentId,
    id,
    flex,
    isInitialized: false,
  };
}

function makeInitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
  topic: Topic,
): InitializedPanelNode {
  const topicTypeName = topic.typeName;

  const defaultVisualization = getDefaultVisualization(topicTypeName);

  return {
    type: "panel",
    id,
    parentId,
    flex,
    isInitialized: true,
    topicName: topic.name,
    topicTypeName,
    fields: [],
    visualization: defaultVisualization,
    selectedTag: null,
    colorizeImage: false,
    imageRotationDeg: 0,
    imageFlipDirection: null,
    inferenceTopicName: null,
    lockInferenceTransform: true,
    inferenceRotationDeg: 0,
    inferenceFlipDirection: null,
    showDetectionBoundingBoxes: true,
    showDetectionClassNames: true,
    hiddenObjectClassNames: [],
    inferenceImageOpacity: 1,
    inferenceImageClipInset: {
      side: ClipInsetSide.Left,
      percent: 0.5,
    },
    colorizeInferenceImage: false,
    pointCloudPointSize: 0.05,
  };
}

function replaceNode(
  draft: Draft<LayoutState>,
  parentId: LayoutNode["parentId"],
  currentNode: LayoutNode,
  replacementNode: LayoutNode,
): void {
  if (parentId === null) {
    draft.root = replacementNode;
  } else {
    const parent = getContainer(draft, parentId);

    if (currentNode === parent.firstChild) {
      parent.firstChild = replacementNode;
    } else {
      parent.secondChild = replacementNode;
    }
  }
}

function getUninitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): UninitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(
    !panel.isInitialized,
    `Panel with ID ${panelId} is already initialized`,
  );

  return panel;
}

function getInitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): InitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(panel.isInitialized, `Panel with ID ${panelId} is not initialized`);

  return panel;
}

function getPanel(
  draft: Draft<LayoutState>,
  nodeId: PanelNode["id"],
): PanelNode {
  const node = getNode(draft, nodeId);

  invariant(node.type === "panel", `Node with ID ${nodeId} is not a panel`);

  return node;
}

function getContainer(
  draft: Draft<LayoutState>,
  nodeId: ContainerNode["id"],
): ContainerNode {
  const node = getNode(draft, nodeId);

  invariant(
    node.type === "container",
    `Node with ID ${nodeId} is not a container`,
  );

  return node;
}

function getNode(
  draft: Draft<LayoutState>,
  nodeId: LayoutNode["id"],
): LayoutNode {
  let maybeNode: LayoutNode | undefined = undefined;

  function visitor(node: LayoutNode) {
    if (node.id === nodeId) {
      maybeNode = node;
      return false;
    }
  }

  walkLayoutTree(draft.root, visitor);

  invariant(maybeNode !== undefined, `Node with ID ${nodeId} does not exist`);

  return maybeNode;
}

function calculateNewRotationDeg(
  currentRotationDeg: number,
  direction: RotationDirection,
): number {
  const rotateByDeg =
    direction === RotationDirection.Left
      ? -IMAGE_ROTATION_STEP_MAGNITUDE_DEG
      : IMAGE_ROTATION_STEP_MAGNITUDE_DEG;

  return currentRotationDeg + rotateByDeg;
}
