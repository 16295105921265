import { useCallback } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import type { Log } from "~/lqs";

interface UsePlayerParams {
  logId: Log["id"] | null;
  initialTime: bigint | null;
  setLogId: (logId: Log["id"]) => void;
}

const logIdParam = withDefault(StringParam, null);
const initialTimeParam = withDefault(StringParam, null);
const queryParamsConfig = {
  logId: logIdParam,
  t: initialTimeParam,
};

export function usePlayerParams(): UsePlayerParams {
  // TODO: I think it'd be better to split this up somehow since choosing logs
  //       isn't really part of the baseline player. Can address after demo
  //       is ready.
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig);

  const setLogId = useCallback(
    (logId: string) => {
      // The initial timestamp param is specific to a given log ID, so changing
      // the log ID requires the initial timestamp param to be stripped from
      // the URL if present
      setQueryParams({ logId, t: undefined });
    },
    [setQueryParams],
  );

  return {
    logId: queryParams.logId,
    initialTime:
      queryParams.t == null ? null : safeParseEncodedNanoseconds(queryParams.t),
    setLogId,
  };
}

function safeParseEncodedNanoseconds(t: string): bigint | null {
  try {
    return BigInt(t);
  } catch {
    return null;
  }
}
