import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  SortDirection,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Record } from "~/lqs";
import { LqsResourceTable, useRecords } from "~/lqs";
import {
  DataStoreLink,
  makeNewRecordLocation,
  makeRecordLocation,
  useTopicParams,
} from "~/paths";
import { listRecordsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Record>> = [
  {
    header: "Timestamp",
    sortKey: "timestamp",
    renderCell(record) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeRecordLocation({
              topicId: record.topicId,
              timestamp: record.timestamp,
            })}
          >
            <Typography variant="body2">{String(record.timestamp)}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
    defaultHidden: true,
  },
  {
    accessor: "ingestionId",
    dataType: "foreign-key",
    resourceType: "ingestion",
  },
  {
    accessor: "dataOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "dataLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkCompression",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "chunkOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useRecordSearchRequest, RecordSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listRecordsSchema,
    defaultOrder: "timestamp",
    defaultSortDirection: SortDirection.Asc,
  });

export function RecordTable() {
  const { topicId } = useTopicParams();

  const [request, setRequest] = useRecordSearchRequest();

  const searchQuery = useRecords(topicId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="record"
      resourceCreateLocation={makeNewRecordLocation({ topicId })}
      getRowKey={(record) => String(record.timestamp)}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
