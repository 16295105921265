import React from "react";
import { Button, styled, Typography } from "@mui/material";
import type { PointsMaterialProps, PointsProps } from "@react-three/fiber";
import { Canvas } from "@react-three/fiber";
import { TimerSand } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { Error } from "~/components/Error";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout } from "../../components";
import { useUpdatePanelBuffering } from "../../hooks";
import type { InitializedPanelNode } from "../../panels";
import { InteractivityIndicator } from "./interactivity-indicator";
import { CameraControls } from "./three-d-visualization-controls";
import { useThreeDRecords } from "./use-three-d-records";

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      points: PointsProps;
      pointsMaterial: PointsMaterialProps;
    }
  }
}

const StyledCanvas = styled(Canvas)({
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
});

export function ThreeDVisualization({
  panel,
  topic,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
}) {
  const playerActions = usePlayerActions();

  const { snapshot, isPlaceholder } = useThreeDRecords(topic);

  useUpdatePanelBuffering(snapshot.status === "pending" || isPlaceholder);

  let content;
  if (snapshot.status === "pending") {
    content = <LoadingFeedback description="3D models" />;
  } else if (snapshot.status === "rejected") {
    content = (
      <Error>
        <Typography variant="h5" component="p">
          An error occurred
        </Typography>
      </Error>
    );
  } else {
    const mostRecentRecord = snapshot.value.findLast(
      (record) => record.timestamp <= snapshot.request.timestamp,
    );

    let firstRecordSkipTimestamp: bigint | undefined = undefined;
    if (mostRecentRecord === undefined) {
      firstRecordSkipTimestamp = snapshot.value.at(0)?.timestamp;
    }

    content = (
      <>
        <StyledCanvas frameloop="demand" scene={{ rotation: [Math.PI, 0, 0] }}>
          {mostRecentRecord?.data != null && (
            /* eslint-disable react/no-unknown-property */
            <points geometry={mostRecentRecord.data}>
              <pointsMaterial vertexColors size={panel.pointCloudPointSize} />
            </points>
            /* eslint-enable react/no-unknown-property */
          )}
          <CameraControls panel={panel} />
        </StyledCanvas>
        {firstRecordSkipTimestamp !== undefined && (
          <Center sx={{ position: "absolute", left: 0, top: 0 }}>
            <TimerSand fontSize="large" />
            <Typography variant="h5" component="p" paragraph>
              No recent point cloud
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => playerActions.seek(firstRecordSkipTimestamp)}
            >
              Skip to First Point Cloud
            </Button>
          </Center>
        )}
      </>
    );
  }

  return (
    <PanelLayout>
      {content}
      {isPlaceholder ? (
        <LoadingFeedback description="3D models" />
      ) : snapshot.status === "fulfilled" ? (
        <InteractivityIndicator />
      ) : null}
    </PanelLayout>
  );
}
