import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { invariant } from "~/lib/invariant";
import { useDataStoreClients } from "../context";
import type {
  ListUsersRequest,
  MeDataResponse,
  User,
  UserCreateRequest,
  UserDataResponse,
  UserListResponse,
  UserUpdateRequest,
} from "../sdk";
import type { LqsMutationOptions, LqsQueryOptions } from "./utils";
import { createResourceCrudHooks } from "./utils";

export const { queryKeyFactory: userKeys } = createResourceCrudHooks({
  baseQueryKey: "users",
  getIdentifier(user: User) {
    return user.id;
  },
  listResource({ signal }, { userApi }, request: ListUsersRequest) {
    return userApi.listUsers(request, { signal });
  },
  fetchResource({ signal }, { userApi }, userId: User["id"]) {
    return userApi.fetchUser({ userId }, { signal });
  },
  createResource({ userApi }, request: UserCreateRequest) {
    return userApi.createUser({ userCreateRequest: request });
  },
  updateResource({ userApi }, userId: User["id"], updates: UserUpdateRequest) {
    return userApi.updateUser({ userId, userUpdateRequest: updates });
  },
  deleteResource({ userApi }, userId: User["id"]) {
    return userApi.deleteUser({ userId });
  },
});

export function useUsersQueryOptionsFactory(): (
  request: ListUsersRequest,
) => LqsQueryOptions<UserListResponse> {
  const { userApi } = useDataStoreClients();

  return (request) => ({
    queryKey: userKeys.list(request),
    queryFn({ signal }) {
      return userApi.listUsers(request, { signal });
    },
  });
}

export function useUserQueryOptionsFactory(): (
  userId: User["id"] | null,
) => LqsQueryOptions<UserDataResponse, "enabled"> {
  const { userApi } = useDataStoreClients();

  return (userId) => {
    const enabled = userId !== null;

    return {
      queryKey: userKeys.fetch(userId),
      queryFn({ signal }) {
        invariant(enabled, "User ID not provided");

        return userApi.fetchUser({ userId }, { signal });
      },
      enabled,
    };
  };
}

export function useUserCreateMutationOptionsFactory(): () => LqsMutationOptions<
  UserDataResponse,
  UserCreateRequest,
  "onSuccess"
> {
  const { userApi } = useDataStoreClients();

  const queryClient = useQueryClient();

  return () => ({
    mutationFn(request) {
      return userApi.createUser({ userCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData(userKeys.fetch(response.data.id), response);
    },
  });
}

export function useUserUpdateMutationOptionsFactory(): (
  userId: User["id"],
) => LqsMutationOptions<UserDataResponse, UserUpdateRequest, "onSuccess"> {
  const { userApi } = useDataStoreClients();

  const queryClient = useQueryClient();

  return (userId) => ({
    mutationFn(request) {
      return userApi.updateUser({ userId, userUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData(userKeys.fetch(userId), response);
    },
  });
}

export function useUserDeleteMutationOptionsFactory(): (
  userId: User["id"],
) => LqsMutationOptions<void, void> {
  const { userApi } = useDataStoreClients();

  return (userId) => ({
    mutationFn() {
      return userApi.deleteUser({ userId });
    },
  });
}

export function useCurrentUserQueryOptionsFactory(): () => LqsQueryOptions<MeDataResponse> {
  const { userApi } = useDataStoreClients();

  return () => ({
    queryKey: ["current-user"],
    queryFn({ signal }) {
      return userApi.fetchUserMe({ signal });
    },
  });
}

export function useCurrentUser<TData = MeDataResponse>(
  options?: Pick<UseQueryOptions<MeDataResponse, unknown, TData>, "select">,
): UseQueryResult<TData> {
  const createCurrentUserQueryOptions = useCurrentUserQueryOptionsFactory();

  return useQuery({
    ...options,
    ...createCurrentUserQueryOptions(),
  });
}
