import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import type { KeyFactory } from "~/types";
import { stringifyBigintFields } from "~/utils";
import { useDataStoreClients } from "../context";
import type {
  ListRecordsRequest,
  ListTopicsRequest,
  Record,
  RecordCreateRequest,
  RecordDataResponse,
  RecordListResponse,
  RecordUpdateRequest,
  Topic,
  TopicCreateRequest,
  TopicDataResponse,
  TopicListResponse,
  TopicUpdateRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks, getInitialDetailsData } from "./utils";

export const {
  queryKeyFactory: topicKeys,
  useList: useTopics,
  useFetch: useTopic,
  useCreate: useCreateTopic,
  useUpdate: useUpdateTopic,
  useDelete: useDeleteTopic,
} = createResourceCrudHooks({
  baseQueryKey: "topics",
  getIdentifier(topic: Topic) {
    return topic.id;
  },
  listResource({ signal }, { topicApi }, request: ListTopicsRequest) {
    return topicApi.listTopics(request, { signal });
  },
  fetchResource({ signal }, { topicApi }, topicId: Topic["id"]) {
    return topicApi.fetchTopic({ topicId }, { signal });
  },
  createResource({ topicApi }, request: TopicCreateRequest) {
    return topicApi.createTopic({ topicCreateRequest: request });
  },
  updateResource(
    { topicApi },
    topicId: Topic["id"],
    updates: TopicUpdateRequest,
  ) {
    return topicApi.updateTopic({ topicId, topicUpdateRequest: updates });
  },
  deleteResource({ topicApi }, topicId: Topic["id"]) {
    return topicApi.deleteTopic({ topicId });
  },
});

export function useTopicsQueryOptionsFactory(): (
  request: ListTopicsRequest,
) => LqsQueryOptions<TopicListResponse> {
  const { topicApi } = useDataStoreClients();

  return (request) => ({
    queryKey: topicKeys.list(request),
    queryFn({ signal }) {
      return topicApi.listTopics(request, { signal });
    },
  });
}

export function useTopicQueryOptionsFactory(): (
  topicId: Topic["id"] | null,
) => LqsQueryOptions<TopicDataResponse, "enabled"> {
  const { topicApi } = useDataStoreClients();

  return (topicId) => {
    const enabled = topicId !== null;

    return {
      queryKey: topicKeys.fetch(topicId),
      queryFn({ signal }) {
        invariant(enabled, "Topic ID not provided");

        return topicApi.fetchTopic({ topicId }, { signal });
      },
      enabled,
    };
  };
}

export type TopicKeys = KeyFactory<typeof topicKeys>;

export const recordKeys = {
  all: (topicId: Topic["id"]) =>
    [...topicKeys.all, topicId, "records"] as const,
  lists: (topicId: Topic["id"]) =>
    [...recordKeys.all(topicId), "list"] as const,
  list: (
    topicId: Topic["id"],
    request: StrictOmit<ListRecordsRequest, "topicId">,
  ) => [...recordKeys.lists(topicId), stringifyBigintFields(request)] as const,
  fetches: (topicId: Topic["id"]) =>
    [...recordKeys.all(topicId), "fetch"] as const,
  fetch: (topicId: Topic["id"], timestamp: Record["timestamp"]) =>
    [...recordKeys.fetches(topicId), String(timestamp)] as const,
};

export type RecordKeys = KeyFactory<typeof recordKeys>;

export function useRecords<TData = RecordListResponse>(
  topicId: Topic["id"],
  request: StrictOmit<ListRecordsRequest, "topicId">,
  options?: UseQueryOptions<
    RecordListResponse,
    unknown,
    TData,
    RecordKeys["list"]
  >,
): UseQueryResult<TData> {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: recordKeys.list(topicId, request),
    queryFn(context) {
      return topicApi.listRecords({ topicId, ...request }, context);
    },
    ...options,
  });
}

export function useRecord<TData = RecordDataResponse>(
  topicId: Topic["id"],
  timestamp: Record["timestamp"],
  options?: StrictOmit<
    UseQueryOptions<RecordDataResponse, unknown, TData, RecordKeys["fetch"]>,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: recordKeys.fetch(topicId, timestamp),
    queryFn(context) {
      return topicApi.fetchRecord({ topicId, timestamp }, context);
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        recordKeys.lists(topicId),
        (record: Record) =>
          record.topicId === topicId && record.timestamp === timestamp,
      );
    },
  });
}

export function useCreateRecord(
  topicId: Topic["id"],
): UseMutationResult<RecordDataResponse, unknown, RecordCreateRequest> {
  const queryClient = useQueryClient();

  const { topicApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return topicApi.createRecord({ topicId, recordCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<RecordDataResponse>(
        recordKeys.fetch(topicId, response.data.timestamp),
        response,
      );
    },
  });
}

export function useUpdateRecord(
  topicId: Topic["id"],
  timestamp: Record["timestamp"],
): UseMutationResult<RecordDataResponse, unknown, RecordUpdateRequest> {
  const queryClient = useQueryClient();

  const { topicApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return topicApi.updateRecord({
        topicId,
        timestamp,
        recordUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<RecordDataResponse>(
        recordKeys.fetch(topicId, response.data.timestamp),
        response,
      );
    },
  });
}

export function useDeleteRecord(
  topicId: Topic["id"],
  timestamp: Record["timestamp"],
): UseMutationResult<void, unknown, void> {
  const { topicApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return topicApi.deleteRecord({ topicId, timestamp });
    },
  });
}
