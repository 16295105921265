import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { TopicTree } from "~/components/topic-tree";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import { PanelLayout } from "../components";
import type { UninitializedPanelNode } from "../panels";

export default function TopicSelector({
  panelId,
  topics,
}: {
  panelId: UninitializedPanelNode["id"];
  topics: ReadonlyArray<Topic>;
}) {
  const { selectPanelTopic: dispatchSelectPanelTopic } = usePlayerActions();

  const handleSelect = useCallback(
    (topic: Topic) => {
      dispatchSelectPanelTopic(panelId, topic);
    },
    [dispatchSelectPanelTopic, panelId],
  );

  return (
    <PanelLayout>
      <Box sx={{ width: 1, height: 1, overflowY: "auto" }}>
        <Box sx={{ width: 1, maxWidth: "60ch", p: 2 }}>
          <TopicTree topics={topics} onSelect={handleSelect} />
        </Box>
      </Box>
    </PanelLayout>
  );
}
