import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { DefaultErrorFallback } from "~/errors/DefaultErrorFallback";
import ThemeProvider from "~/providers/ThemeProvider";
import { SettingsProvider } from "~/settings/provider";
import { DataStoreClientsProvider } from "./data-store-clients-provider";
import { DemoAppPage } from "./page";

export function App() {
  return (
    <SettingsProvider>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <SnackbarProvider maxSnack={1}>
                <DataStoreClientsProvider>
                  <Routes>
                    <Route path="/" element={<DemoAppPage />} />
                  </Routes>
                </DataStoreClientsProvider>
              </SnackbarProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </SettingsProvider>
  );
}
