import { Backdrop, CircularProgress, Fade, Typography } from "@mui/material";

export function LoadingFeedback({ description }: { description: string }) {
  return (
    <Backdrop open sx={{ position: "absolute", flexDirection: "column" }}>
      <CircularProgress />
      <Typography variant="h5" component="p">
        Fetching {description} for playback
      </Typography>
      <Fade in style={{ transitionDelay: "5s" }}>
        <Typography variant="h5" component="p">
          Taking longer than expected...
        </Typography>
      </Fade>
    </Backdrop>
  );
}
