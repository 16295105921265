import React, { useState } from "react";
import type { StoreApi } from "zustand";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSafeContext } from "~/contexts";
import type { Topic } from "~/lqs";

interface TagFilters {
  topicId: Topic["id"] | null;
}

interface TagStore {
  filters: TagFilters | null;
  actions: {
    toggleTags: (filters?: TagFilters) => void;
  };
}

function createTagStore() {
  return createStore<TagStore>()(
    immer((set) => {
      return {
        filters: null,
        actions: {
          toggleTags(filters = { topicId: null }) {
            set((state) => {
              if (state.filters === null) {
                state.filters = filters;
              } else if (filters.topicId === state.filters.topicId) {
                state.filters = null;
              } else {
                state.filters = filters;
              }
            });
          },
        },
      };
    }),
  );
}

const [useTagStoreContext, TagStoreContext] =
  createSafeContext<StoreApi<TagStore>>("TagStore");

// Tag store must be scoped to a given log, meaning there can't be a single
// store for the entire app. Instead, the `<TagStoreProvider />` will create
// a fresh store whenever it mounts. This component should be rendered under
// the `<LogPlaybackSourceProvider />`
export function TagStoreProvider({ children }: { children: React.ReactNode }) {
  const [tagStore] = useState(createTagStore);

  return (
    <TagStoreContext.Provider value={tagStore}>
      {children}
    </TagStoreContext.Provider>
  );
}

export function useTagFilters() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.filters);
}

export function useAreLogTagsShowing() {
  const tagStore = useTagStoreContext();

  return useStore(
    tagStore,
    (store) => store.filters !== null && store.filters.topicId === null,
  );
}

export function useAreTopicTagsShowing(topicId: Topic["id"]) {
  const tagStore = useTagStoreContext();

  return useStore(
    tagStore,
    (store) => store.filters !== null && store.filters.topicId === topicId,
  );
}

export function useShouldShowTags() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.filters !== null);
}

export function useTagActions() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.actions);
}
