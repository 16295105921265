import "@mui/material/types/OverridableComponentAugmentation";
import { createRoot } from "react-dom/client";
import * as demoApp from "~/apps/demo";
import * as studioApp from "~/apps/studio";

const container = document.getElementById("root")!;
const root = createRoot(container);

if (process.env.REACT_APP_APP_ENV === "demo") {
  demoApp.start(root);
} else if (process.env.REACT_APP_APP_ENV === "studio") {
  studioApp.start(root);
} else {
  throw new Error(`Unknown app environment: ${process.env.REACT_APP_APP_ENV}`);
}
