import type React from "react";
import { useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import * as z from "zod";
import { createQueryClient } from "~/create-query-client";
import {
  APIKeyApi,
  DataStoreClientsContext,
  DigestionApi,
  GroupApi,
  IngestionApi,
  LabelApi,
  LogApi,
  ObjectStoreApi,
  RoleApi,
  TopicApi,
  UserApi,
  WorkflowApi,
} from "~/lqs";
import { createLqsSdkConfiguration } from "./config";

export function DataStoreClientsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dataStoreId = useDataStoreId();

  const [clients] = useState(() => {
    const configuration = createLqsSdkConfiguration(dataStoreId);

    return {
      apiKeyApi: new APIKeyApi(configuration),
      digestionApi: new DigestionApi(configuration),
      groupApi: new GroupApi(configuration),
      ingestionApi: new IngestionApi(configuration),
      labelApi: new LabelApi(configuration),
      logApi: new LogApi(configuration),
      objectStoreApi: new ObjectStoreApi(configuration),
      roleApi: new RoleApi(configuration),
      topicApi: new TopicApi(configuration),
      userApi: new UserApi(configuration),
      workflowApi: new WorkflowApi(configuration),
    };
  });

  const [queryClient] = useState(createQueryClient);

  return (
    <DataStoreClientsContext.Provider value={clients}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </DataStoreClientsContext.Provider>
  );
}

function useDataStoreId(): string {
  const [searchParams] = useSearchParams();

  const [dataStoreId] = useState(() =>
    z.string().uuid().parse(searchParams.get("dataStoreId")),
  );

  return dataStoreId;
}
