import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { secondsToMilliseconds } from "~/lib/dates";
import { circumventPagination, useDataStoreClients } from "~/lqs";
import type { ListRecordsRequest, Record, Topic } from "~/lqs";
import { SampleFrequency } from "../types";

interface UseAllRecordsParameters {
  topic: Topic;
  enabled?: boolean;
}

export function useAllRecords({
  topic,
  enabled,
}: UseAllRecordsParameters): UseQueryResult<Array<Record>> {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: ["all-records", topic.id],
    async queryFn({ signal }) {
      const baseRequest: ListRecordsRequest = {
        topicId: topic.id,
        sort: "asc",
        order: "timestamp",
        frequency: SampleFrequency.Second,
      };

      const { data } = await circumventPagination(
        topicApi.listRecords.bind(topicApi),
        100,
        baseRequest,
        { signal },
      );

      return data;
    },
    enabled,
    cacheTime: secondsToMilliseconds(20),
  });
}
