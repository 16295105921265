import { invariant } from "~/lib/invariant";
import { useDataStoreClients } from "../context";
import type {
  Label,
  LabelCreateRequest,
  LabelDataResponse,
  LabelListResponse,
  LabelUpdateRequest,
  ListLabelsRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: labelKeys,
  useList: useLabels,
  useFetch: useLabel,
  useCreate: useCreateLabel,
  useUpdate: useUpdateLabel,
  useDelete: useDeleteLabel,
} = createResourceCrudHooks({
  baseQueryKey: "labels",
  getIdentifier(label: Label) {
    return label.id;
  },
  listResource({ signal }, { labelApi }, request: ListLabelsRequest) {
    return labelApi.listLabels(request, { signal });
  },
  fetchResource({ signal }, { labelApi }, labelId: Label["id"]) {
    return labelApi.fetchLabel({ labelId }, { signal });
  },
  createResource({ labelApi }, request: LabelCreateRequest) {
    return labelApi.createLabel({ labelCreateRequest: request });
  },
  updateResource(
    { labelApi },
    labelId: Label["id"],
    updates: LabelUpdateRequest,
  ) {
    return labelApi.updateLabel({ labelId, labelUpdateRequest: updates });
  },
  deleteResource({ labelApi }, labelId: Label["id"]) {
    return labelApi.deleteLabel({ labelId });
  },
});

export function useLabelsQueryOptionsFactory(): (
  request: ListLabelsRequest,
) => LqsQueryOptions<LabelListResponse> {
  const { labelApi } = useDataStoreClients();

  return (request) => ({
    queryKey: labelKeys.list(request),
    queryFn({ signal }) {
      return labelApi.listLabels(request, { signal });
    },
  });
}

export function useLabelQueryOptionsFactory(): (
  labelId: Label["id"] | null,
) => LqsQueryOptions<LabelDataResponse, "enabled"> {
  const { labelApi } = useDataStoreClients();

  return (labelId) => {
    const enabled = labelId !== null;

    return {
      queryKey: labelKeys.fetch(labelId),
      queryFn({ signal }) {
        invariant(enabled, "Label ID not provided");

        return labelApi.fetchLabel({ labelId }, { signal });
      },
      enabled,
    };
  };
}
