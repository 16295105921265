import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "~/components/Error";
import { Loading } from "~/components/Loading";
import { find } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import { PanelLayout } from "../components";
import { usePanelContext, VisualizationType } from "../panels";
import { usePlaybackSource } from "../playback";
import { ChartVisualization } from "./chart-visualization";
import { ImageVisualization } from "./image-visualization";
import type { MapVisualizationProps } from "./map-visualization";
import { ThreeDVisualization } from "./three-d-visualization";
import TimelineVisualization from "./timeline-visualization";
import TopicSelector from "./topic-selector";

const LazyMapVisualization = React.lazy(() => import("./map-visualization"));

export function VisualizationSwitch({
  topics,
}: {
  topics: ReadonlyArray<Topic>;
}) {
  const playbackSource = usePlaybackSource();

  const panel = usePanelContext();

  if (playbackSource.isLoading) {
    // Visualizations expect playback source to be loaded when they're rendered
    return <Loading type="circular" />;
  } else if (!panel.isInitialized) {
    return <TopicSelector panelId={panel.id} topics={topics} />;
  } else {
    const topic = find(topics, { name: panel.topicName });

    if (topic === undefined) {
      return (
        <PanelLayout>
          <Error icon={<DoNotDisturb fontSize="large" color="error" />}>
            <Typography variant="h5" component="p">
              <code>{panel.topicName}</code> is not in this log
            </Typography>
          </Error>
        </PanelLayout>
      );
    } else {
      switch (panel.visualization) {
        case VisualizationType.Timeline: {
          return <TimelineVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Chart: {
          return <ChartVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Image: {
          return (
            <ImageVisualization
              panel={panel}
              topic={topic}
              playerTopics={topics}
            />
          );
        }
        case VisualizationType.Map: {
          return renderLazyMapVisualization({ panel, topic });
        }
        case VisualizationType.ThreeD: {
          return <ThreeDVisualization panel={panel} topic={topic} />;
        }
        default: {
          assertNever(panel.visualization);
        }
      }
    }
  }
}

function renderLazyMapVisualization(props: MapVisualizationProps) {
  // TODO: Don't pull app-specific checks into the player package
  if (process.env.REACT_APP_APP_ENV === "demo") {
    return renderPanelFallback(
      <Error>
        <Typography variant="h5" component="p">
          Map visualization unavailable for demo
        </Typography>
      </Error>,
    );
  }

  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <Error>
          <Typography variant="h5" component="p">
            Could not initialize map view
          </Typography>
        </Error>,
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        <LazyMapVisualization {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout>{children}</PanelLayout>;
}
