import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import type { KeyFactory } from "~/types";
import { useDataStoreClients } from "../context";
import type {
  Ingestion,
  IngestionCreateRequest,
  IngestionDataResponse,
  IngestionListResponse,
  IngestionPart,
  IngestionPartCreateRequest,
  IngestionPartDataResponse,
  IngestionPartListResponse,
  IngestionPartUpdateRequest,
  IngestionUpdateRequest,
  ListIngestionPartsRequest,
  ListIngestionsRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks, getInitialDetailsData } from "./utils";

export const {
  queryKeyFactory: ingestionKeys,
  useList: useIngestions,
  useFetch: useIngestion,
  useCreate: useCreateIngestion,
  useUpdate: useUpdateIngestion,
  useDelete: useDeleteIngestion,
} = createResourceCrudHooks({
  baseQueryKey: "ingestions",
  getIdentifier(ingestion: Ingestion) {
    return ingestion.id;
  },
  listResource({ signal }, { ingestionApi }, request: ListIngestionsRequest) {
    return ingestionApi.listIngestions(request, { signal });
  },
  fetchResource({ signal }, { ingestionApi }, ingestionId: Ingestion["id"]) {
    return ingestionApi.fetchIngestion({ ingestionId }, { signal });
  },
  createResource({ ingestionApi }, request: IngestionCreateRequest) {
    return ingestionApi.createIngestion({ ingestionCreateRequest: request });
  },
  updateResource(
    { ingestionApi },
    ingestionId: Ingestion["id"],
    updates: IngestionUpdateRequest,
  ) {
    return ingestionApi.updateIngestion({
      ingestionId,
      ingestionUpdateRequest: updates,
    });
  },
  deleteResource({ ingestionApi }, ingestionId: Ingestion["id"]) {
    return ingestionApi.deleteIngestion({ ingestionId });
  },
});

export function useIngestionsQueryOptionsFactory(): (
  request: ListIngestionsRequest,
) => LqsQueryOptions<IngestionListResponse> {
  const { ingestionApi } = useDataStoreClients();

  return (request) => ({
    queryKey: ingestionKeys.list(request),
    queryFn({ signal }) {
      return ingestionApi.listIngestions(request, { signal });
    },
  });
}

export function useIngestionQueryOptionsFactory(): (
  ingestionId: Ingestion["id"] | null,
) => LqsQueryOptions<IngestionDataResponse, "enabled"> {
  const { ingestionApi } = useDataStoreClients();

  return (ingestionId) => {
    const enabled = ingestionId !== null;

    return {
      queryKey: ingestionKeys.fetch(ingestionId),
      queryFn({ signal }) {
        invariant(enabled, "Ingestion ID not provided");

        return ingestionApi.fetchIngestion({ ingestionId }, { signal });
      },
      enabled,
    };
  };
}

const ingestionPartKeys = {
  all: (ingestionId: Ingestion["id"]) =>
    [...ingestionKeys.fetch(ingestionId), "parts"] as const,
  lists: (ingestionId: Ingestion["id"]) =>
    [...ingestionPartKeys.all(ingestionId), "list"] as const,
  list: (
    ingestionId: Ingestion["id"],
    request: StrictOmit<ListIngestionPartsRequest, "ingestionId">,
  ) => [...ingestionPartKeys.lists(ingestionId), request] as const,
  fetches: (ingestionId: Ingestion["id"]) =>
    [...ingestionPartKeys.all(ingestionId), "fetch"] as const,
  fetch: (ingestionId: Ingestion["id"], ingestionPartId: IngestionPart["id"]) =>
    [...ingestionPartKeys.fetches(ingestionId), ingestionPartId] as const,
};

type IngestionPartKeys = KeyFactory<typeof ingestionPartKeys>;

export function useIngestionParts<TData = IngestionPartListResponse>(
  ingestionId: Ingestion["id"],
  request: StrictOmit<ListIngestionPartsRequest, "ingestionId">,
  options?: UseQueryOptions<
    IngestionPartListResponse,
    unknown,
    TData,
    IngestionPartKeys["list"]
  >,
): UseQueryResult<TData> {
  const { ingestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: ingestionPartKeys.list(ingestionId, request),
    queryFn({ signal }) {
      return ingestionApi.listIngestionParts(
        { ingestionId, ...request },
        { signal },
      );
    },
    ...options,
  });
}

export function useIngestionPart<TData = IngestionPartDataResponse>(
  ingestionId: Ingestion["id"],
  ingestionPartId: IngestionPart["id"],
  options?: StrictOmit<
    UseQueryOptions<
      IngestionPartDataResponse,
      unknown,
      TData,
      IngestionPartKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { ingestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: ingestionPartKeys.fetch(ingestionId, ingestionPartId),
    queryFn({ signal }) {
      return ingestionApi.fetchIngestionPart(
        { ingestionId, ingestionPartId },
        { signal },
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        ingestionPartKeys.lists(ingestionId),
        (ingestionPart: IngestionPart) =>
          ingestionPart.ingestionId === ingestionId &&
          ingestionPart.id === ingestionPartId,
      );
    },
  });
}

export function useCreateIngestionPart(
  ingestionId: Ingestion["id"],
): UseMutationResult<
  IngestionPartDataResponse,
  unknown,
  IngestionPartCreateRequest
> {
  const queryClient = useQueryClient();

  const { ingestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return ingestionApi.createIngestionPart({
        ingestionId,
        ingestionPartCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<IngestionPartDataResponse>(
        ingestionPartKeys.fetch(ingestionId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateIngestionPart(
  ingestionId: Ingestion["id"],
  ingestionPartId: IngestionPart["id"],
): UseMutationResult<
  IngestionPartDataResponse,
  unknown,
  IngestionPartUpdateRequest
> {
  const queryClient = useQueryClient();

  const { ingestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(updates) {
      return ingestionApi.updateIngestionPart({
        ingestionId,
        ingestionPartId,
        ingestionPartUpdateRequest: updates,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<IngestionPartDataResponse>(
        ingestionPartKeys.fetch(ingestionId, response.data.id),
        response,
      );
    },
  });
}

export function useDeleteIngestionPart(
  ingestionId: Ingestion["id"],
  ingestionPartId: IngestionPart["id"],
): UseMutationResult<void, unknown, void> {
  const { ingestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return ingestionApi.deleteIngestionPart({ ingestionId, ingestionPartId });
    },
  });
}
