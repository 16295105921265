import React, { useState } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { createDataStoreManagementApiConfiguration } from "~/apps/studio/factories";
import { createSafeContext } from "~/contexts";
import { createQueryClient } from "~/create-query-client";
import { DataStoreApi, DataStoreAssociationApi, RoleApi, UserApi } from "./sdk";
import type { DSMClients } from "./types";

interface DSMProviderProps {
  children: React.ReactNode;
}

export const [useDsmContext, DSMContext] = createSafeContext<DSMClients>("DSM");

export const DSMQueryClientContext = React.createContext<
  QueryClient | undefined
>(undefined);

export function DSMProvider({ children }: DSMProviderProps) {
  const [clients] = useState((): DSMClients => {
    const configuration = createDataStoreManagementApiConfiguration();

    return {
      dataStoreApi: new DataStoreApi(configuration),
      dataStoreAssociationApi: new DataStoreAssociationApi(configuration),
      roleApi: new RoleApi(configuration),
      userApi: new UserApi(configuration),
    };
  });

  const [queryClient] = useState(createQueryClient);

  return (
    <DSMContext.Provider value={clients}>
      <QueryClientProvider
        client={queryClient}
        // Isolate DSM queries and mutations while still being accessible
        // anywhere in the tree if this context is passed to the TanStack hooks.
        context={DSMQueryClientContext}
      >
        {children}
      </QueryClientProvider>
    </DSMContext.Provider>
  );
}
