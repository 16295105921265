import { useMemo } from "react";
import { get } from "~/lib/std";
import type { LayoutProfile } from "~/player";
import { usePlayerLog } from "~/player";

// TODO: Better approach than importing these deeply
import type { RuntimeLayoutProfilesList } from "~/player/panels/api/models";
import { RuntimeProfileArraySchema } from "~/player/panels/api/models";

export function useLogLayoutProfiles(): RuntimeLayoutProfilesList {
  const logQuery = usePlayerLog();

  return useMemo(() => {
    // Default to an empty list if the layouts are missing or malformed
    return RuntimeProfileArraySchema.catch([]).parse(
      get(logQuery.data?.context, "studio.layout_profiles"),
    );
  }, [logQuery.data]);
}

export function useInitialLayoutProfile(): LayoutProfile | undefined {
  const logQuery = usePlayerLog();

  const layoutProfiles = useLogLayoutProfiles();

  if (!logQuery.isSuccess) {
    return;
  }

  const defaultProfileName = get(
    logQuery.data.context,
    "studio.default_layout_profile",
  );

  return layoutProfiles.find(
    (layoutProfile) => layoutProfile.name === defaultProfileName,
  );
}
