import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Label, LqsColumn } from "~/lqs";
import { LqsResourceTable, useLabels } from "~/lqs";
import {
  DataStoreLink,
  makeLabelLocation,
  makeNewLabelLocation,
} from "~/paths";
import { listLabelsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Label>> = [
  {
    header: "Value",
    sortKey: "value",
    renderCell(label) {
      return (
        <TableCell>
          {label.value}
          <Link
            component={DataStoreLink}
            to={makeLabelLocation({ labelId: label.id })}
          >
            <Typography variant="body2">{label.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useLabelSearchRequest, LabelSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listLabelsSchema,
  });

export function LabelTable() {
  const [request, setRequest] = useLabelSearchRequest();

  const searchQuery = useLabels(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="label"
      resourceCreateLocation={makeNewLabelLocation()}
      getRowKey={(label) => label.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
