import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { IngestionPart, LqsColumn } from "~/lqs";
import { LqsResourceTable, useIngestionParts } from "~/lqs";
import {
  DataStoreLink,
  makeIngestionPartLocation,
  makeNewIngestionPartLocation,
  useIngestionParams,
} from "~/paths";
import { listIngestionPartsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<IngestionPart>> = [
  {
    header: "ID",
    renderCell(ingestionPart) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeIngestionPartLocation({
              ingestionId: ingestionPart.ingestionId,
              ingestionPartId: ingestionPart.id,
            })}
          >
            <Typography>{ingestionPart.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "sequence",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "source",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
    defaultHidden: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [
  useIngestionPartSearchRequest,
  IngestionPartSearchRequestProvider,
] = createSearchRequestProvider({
  columns,
  filterSchema: listIngestionPartsSchema,
});

export function IngestionPartTable() {
  const { ingestionId } = useIngestionParams();

  const [request, setRequest] = useIngestionPartSearchRequest();

  const searchQuery = useIngestionParts(
    ingestionId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="ingestion part"
      resourceCreateLocation={makeNewIngestionPartLocation({
        ingestionId,
      })}
      getRowKey={(ingestionPart) => ingestionPart.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
